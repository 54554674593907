import AddEmployeeComp from "./AddEmployeeComp";
import ParentComp from "./ParentComp";

function AddEmployee() {
  return (
    <>
      <ParentComp title={"إضافة موظف جديد"} prevPage={"إدارة الموظفين"} linkto={"/employee"}>
        <AddEmployeeComp />
      </ParentComp>
    </>
  );
}

export default AddEmployee;
