import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "../assets/images/Image";
import Icon from "./Icon";
import { whatsapp, call, show, hidden } from "../assets/icons/index";
import { AuthCountext } from "./AuthCountext";
import * as dayjs from "dayjs";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import "dayjs/locale/ar"; // import locale
import EmployeeInfo from "./subcomponent/EmployeeInfo";
import ParentComp from "./ParentComp";
import EmployeePlaceHolder from "./subcomponent/EmployeePlaceHolder";

const extend = dayjs.extend;
extend(isLeapYear); // use plugin
dayjs.locale("ar"); // use locale
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function FecthEmployee() {
  const { SERVER_URL, branchId, theme, setTitle, userToken } =
    useContext(AuthCountext);
  const params = useParams();
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [coverSalary, setCoverSalary] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(
          `${SERVER_URL}/fetchemployee/${params.id}/${branchId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.length > 0) {
          setData(json[0]);
        } else {
          setData([]);
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SERVER_URL, branchId, params.id, userToken]);

  const hide = () => {
    setCoverSalary(!coverSalary);
  };

  const employeeInfo = [
    {
      id: data.id,
      info: "increase",
      name: "العلاوة",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "overtime",
      name: "الاضافي",
      kind: "ساعة",
    },
    {
      id: data.id,
      info: "incentives",
      name: "الحافز",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "rewards",
      name: "المكافآت",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "advance",
      name: "السلف",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "cut",
      name: "الخصم",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "absence",
      name: "الغياب",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "vacation",
      name: "الاجازات",
      kind: "يوم",
    },
  ];

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < 1; i++) {
      places.push(<EmployeePlaceHolder />);
    }
    return places;
  };

  return (
    <>
      <ParentComp
        title={data.name}
        prevPage={"ادارة الموظفين"}
        linkto={"/employee/"}
      >
        <div
          className={`${
            isloading
              ? "flex flex-row justify-center align-center "
              : " justify-space-between "
          } flex flex-row position-relative justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
        >
          <Link
            to={`/employee/updateemployee/${data.id}`}
            style={{
              position: "absolute",
              left: "10px",
              top: "10px",
              zIndex: 2,
              width: "100px",
              overflow: "hidden",
            }}
            className={`before-background-color-${theme} before-text-color-${theme} color-${theme} text-center flex justify-center align-center text-decoration p-5 border-radius`}
          >
            <span style={{ zIndex: 2 }}>تعديل</span>
          </Link>

          {isloading ? (
            <Place />
          ) : data ? (
            <>
              {setTitle(data.name)}
              <div
                style={{ minWidth: "40%", minHeight: "580px" }}
                key={data.id}
              >
                <div
                  key="whatKey"
                  className="flex flex-row gap-20 full-width min-width-700 position-relative media-flex-column"
                >
                  <div className="flex flex-column align-start">
                    <div>
                      <Image
                        image={`${SERVER_URL}/${data.photo}`}
                        w={220}
                        h={290}
                        name={data.name}
                      />
                    </div>
                  </div>
                  <div className="flex flex-column justify-space-between">
                    <div
                      className="flex flex-row"
                      style={{ maxWidth: "200px" }}
                    >
                      <div className="width-300">
                        <div>
                          <span>صورة الهوية</span>
                        </div>
                        <Image
                          image={`${SERVER_URL}/${data.idcopy}`}
                          w={70}
                          h={90}
                          name={data.name}
                        />
                      </div>
                      <div className="width-300">
                        <div>
                          <span>صورة الجواز</span>
                        </div>
                        <Image
                          image={`${SERVER_URL}/${data.passport}`}
                          w={70}
                          h={90}
                          name={data.name}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="text-align-start">
                        <span style={{ fontSize: "26px", fontWeight: "bold" }}>
                          {data.name}
                        </span>
                      </div>
                      <div className="opacity-8 font-large flex flex-row align-center gap-5">
                        <span>{data.title}</span> | <span>{data.phone}</span>
                        <Link
                          className="flex align-center"
                          to={`tel:966${data.phone?.slice(1)}`}
                        >
                          <Icon icon={call} w={20} h={20} />
                        </Link>
                        <Link
                          className="flex align-center"
                          to={`https://wa.me/966${data.phone?.slice(1)}`}
                          target="_blank"
                        >
                          <Icon icon={whatsapp} w={20} h={20} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column align-start">
                  <h1>معلومات</h1>
                  <div
                    style={{
                      alignItems: "self-start",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                    key="numberNone"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                      key="numberEight"
                    >
                      {employeeInfo.map((item) => (
                        <EmployeeInfo
                          id={item.id}
                          info={item.info}
                          name={item.name}
                          kind={item.kind}
                          key={item.id}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`flex flex-row flex-wrap justify-start text-align-start full-width gap-20 `}
                key="numberOne"
              >
                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>بيانات شخصية</h1>
                  <ul className="flex flex-column" key="numberTow">
                    <li className="flex flex-row justify-start gap-10 align-center">
                      <span className="font-bold">الراتب: </span>
                      <div className="flex flex-row justify-space-between gap-10 align-center width-70">
                        <span>
                          {coverSalary
                            ? "*".repeat(data.amount?.toString().length)
                            : data.amount}
                        </span>
                        <div className="flex align-center" onClick={hide}>
                          {coverSalary ? (
                            <Icon icon={hidden} w={20} h={20} />
                          ) : (
                            <Icon icon={show} w={20} h={20} />
                          )}
                        </div>
                      </div>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">الايميل: </span>
                      <span>{data.email}</span>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">الجنس: </span>
                      <span>{data.sex}</span>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">الجنسية: </span>
                      <span>{data.countryarnationality}</span>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ الميلاد: </span>
                      <span>{data.birthdate}</span>
                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                      >
                        {dayjs(data.birthdate).fromNow()}
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>الوثائق</h1>
                  <ul className="flex flex-column" key="numberThree">
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">رقم الهوية: </span>
                      <span>{data.idNumber}</span>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ انتهاء الهوية: </span>
                      <span>{data.idExpireDate}</span>
                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                      >
                        {dayjs(data.idExpireDate).fromNow()}
                      </div>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">رقم الجواز: </span>
                      <span>{data.passportNumber}</span>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ انتهاء الجواز: </span>
                      <span>{data.passExpireDate}</span>
                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                      >
                        {dayjs(data.passExpireDate).fromNow()}
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                  key="numberFour"
                >
                  <h1>بيانات الوظيفة</h1>
                  <ul className="flex flex-column" key="numberFive">
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ بداية العمل : </span>
                      <span>{data.startInPosition}</span>
                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                      >
                        {dayjs(data.startInPosition).fromNow()}
                      </div>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ نهاية العمل: </span>
                      <span>
                        {data.endInPosition
                          ? data.endInPosition
                          : "غير محدد المدة"}
                      </span>
                      {data.endInPosition && (
                        <div
                          className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        >
                          {dayjs(data.endInPosition).fromNow()}
                        </div>
                      )}
                    </li>
                  </ul>
                </div>

                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>بيانات العقد</h1>
                  <ul className="flex flex-column" key="numberSix">
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">حالة العقد : </span>
                      <span>
                        {data.inwork === 1 || data.inwork === "1"
                          ? "على رأس العمل"
                          : "انتهى عقده"}
                      </span>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ بداية العقد: </span>
                      <span>{data.start}</span>
                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                      >
                        {dayjs(data.start).fromNow()}
                      </div>
                    </li>
                    <li className="flex flex-row justify-start gap-10">
                      <span className="font-bold">تاريخ نهاية العقد: </span>
                      <span>{data.end ? data.end : "غير محدد المدة"}</span>
                      {data.end && (
                        <div
                          className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        >
                          {dayjs(data.end).fromNow()}
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <h1>لا توجد بيانات</h1>
          )}
        </div>
      </ParentComp>
    </>
  );
}

export default FecthEmployee;
