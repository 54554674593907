import ParentComp from "./ParentComp";
import SalaryCutComp from "./SalaryCutComp";

function SalaryCut() {
  return (
    <>
      <ParentComp title={"الخصومات"}>
        <SalaryCutComp />
      </ParentComp>
    </>
  );
}

export default SalaryCut;
