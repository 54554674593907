import ParentComp from "../ParentComp";
import MainDocsRecordsComp from "./MainDocsRecordsComp";

function MainDocsRecords() {
  return (
    <>
      <ParentComp
        title={"المستندات الثبوتية"}
        prevPage={"الإعدادات"}
        linkto={"/settings/"}
      >
        <MainDocsRecordsComp />
      </ParentComp>
    </>
  );
}

export default MainDocsRecords;
