import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";

function ExpirePlaceHolder() {
  const { theme } = useContext(AuthCountext);

  const Place = () => {
    const places = [];
    for (let i = 0; i < 6; i++) {
      places.push(
        <div
          className={`text-decoration p-l-10 border-radius p-r-10 color-blue flex flex-row justify-space-between align-center place-holder-background-${theme}`}
          style={{ height: "50px", width: "90%", marginBlock: "10px" }}
        ></div>
      );
    }
    return places;
  };
  return (
    <div
      className={`flex flex-column p-20 border-radius shadow-${theme} background-div-${theme} justify-space-between media-full-width`}
      style={{ width: "340px", height: "240px" }}
    >
      <div
        style={{ fontSize: "20px", height: "40px", width: "250px" }}
        className={` place-holder-background-${theme} border-radius`}
      ></div>

      <div
        style={{ width: "300px", height: "360px" }}
        className={`over-flow-scroll over-flow-scroll-${theme}`}
      >
        <Place />
      </div>
    </div>
  );
}

export default ExpirePlaceHolder;
