import React, { useState, useEffect, useContext } from "react";
import { branch, creditCard, job, profile } from "../assets/icons";
import { AuthCountext } from "./AuthCountext";
import DashboardDiv from "./subcomponent/DashboardDiv";
import { Totals } from "./Totals";

const SettingComp = () => {
  const { theme } = useContext(AuthCountext);
  const totals = Totals();
  const [isloading, setIsloading] = useState(true);
 

  const list = [
    {
      name: "branch",
      title: "إعداد الفروع",
      result: totals.totalBranches,
      img: branch,
      link: "/settings/branch",
    },
    {
      name: "maindocs",
      title: "إعداد المستندات",
      result: totals.maindocs,
      img: branch,
      link: "/settings/maindocs",
    },
    {
      name: "paymethod",
      title: "إعداد طرق الصرف",
      result: totals.paymethod,
      img: creditCard,
      link: "/settings/paymethod",
    },
    {
      name: "positiontitle",
      title: "إعداد الوظائف",
      result: totals.jobs,
      img: job,
      link: "/settings/jobs",
    },
    {
      name: "users",
      title: "إدارة المستخدمين",
      result: totals.users,
      img: profile,
      link: "/settings/users",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`flex flex-column background-${theme} color-${theme} gap-10 full-width`}>
      <DashboardDiv list={list} isloading={isloading} />
    </div>
  );
};

export default SettingComp;
