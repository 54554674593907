import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../component/AuthCountext";
import { Select } from "antd";
import { useParams } from "react-router-dom";

function FetchUsers({ setData, defaultValue }) {
  const params = useParams();
  const { SERVER_URL, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/users/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setUsers(json);
      } else {
        setUsers([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  

  useEffect(() => {
    fetchUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };


  return (
    <Select
      name="positionId"
      showSearch
      className={`full-width height-50 border-radius`}
      onChange={(e) =>
        setData((prevData) => {
          return {
            ...prevData,
            userId: e,
          };
        })
      }
      optionFilterProp="label"
      defaultValue={defaultValue ? defaultValue : params.id && Number(params.id)}
      placeholder={isloading ? "جارٍ التحميل" : "المستخدم"}
      options={users.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
    />
  );
}

export default FetchUsers;
