import React, { useContext, useState } from "react";
import { AuthCountext } from "../component/AuthCountext";
import Loader from "../component/Loader";
import Alert from "../component/subcomponent/Alert";
import ThemeSwitch from "./ThemeSwitch";
import CreatUser from "./CreatUser";
import { Input } from "antd";

function SignInScreen() {
  const { login, isLoading, valid, theme, setTitle, usersData } =
    useContext(AuthCountext);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [formData, setFormData] = useState({
    uname: null,
    upass: null,
  });

  const validationFun = () => {
    if (formData.uname !== null && formData.upass !== null) {
      login(formData);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const Valid = () => {
    if (valid !== null) {
      setIsAlert(true);
      setAlertName("alert-warning");
      alertShow();
    }
    return <Alert msg={valid} alert={alertName} />;
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const handleClick = (event) => {
    event.preventDefault();
    console.log(formData);
    validationFun();
  };

  return usersData ? (
    <div
      className={`flex justify-center align-center position-relative full-width p-t-80 p-50 over-flow-scroll background-${theme} color-${theme}`}
      style={{padding: '10px'}}
    >
      <div style={{ position: "absolute", left: "20px", top: "20px" }}>
        <ThemeSwitch />
      </div>
      {valid !== null && <Valid />}
      {setTitle("نظام الرواتب- تسجيل الدخول")}
      {isAlert && valid === null && <Alert msg={alertMsg} alert={alertName} />}
      <form
        className={`flex flex-row justify-center align-center gap-20 p-20 full-width background-div-${theme} shadow-${theme} border-radius`}
        onSubmit={handleClick}
        style={{ maxWidth: "600px", fontSize: 'small' }}
      >
        <div
          className="full-width flex flex-column justify-space-between align-center gap-10 full-width"
          style={{ maxWidth: "500px" }}
        >
          <div>
            <h1 className="text-align-center full-width">مرحبا،</h1>
            <div style={{ fontSize: 'large', fontWeight: "100" }}>
              <h1>الرجاء تسجيل الدخول</h1>
            </div>
          </div>
          <div
            className="full-width flex flex-column justify-center align-center gap-10 full-width"
            style={{ maxWidth: "500px" }}
          >
            <Input
              type="text"
              name="uname"
              className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
              placeholder="اسم المستخدم"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    uname: e.target.value,
                  };
                })
              }
            />
            <Input
              type="password"
              name="upass"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="كلمة السر"
              onChange={(e) =>
                setFormData((prevFormData) => {
                  return {
                    ...prevFormData,
                    upass: e.target.value,
                  };
                })
              }
            />

            <button className={`next-button-${theme} shadow-${theme}`}>
              تسجيل الدخول
            </button>
            <div style={{ height: "100px" }}>
              
              {isLoading && <Loader size="medium" />}
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <CreatUser usersData={usersData} />
  );
}

export default SignInScreen;
