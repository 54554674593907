import React, { useContext } from "react";
import { AuthCountext } from "../component/AuthCountext";
import { Switch } from "antd";
import { SunOutlined, MoonOutlined } from "@ant-design/icons";

function ThemeSwitch() {
  const { setTheme, theme } = useContext(AuthCountext);
  return (
    <Switch
      checkedChildren={<SunOutlined />}
      unCheckedChildren={<MoonOutlined />}
      defaultChecked={theme === 'dark' && true}
      onChange={(checked) => (checked ? setTheme("dark") : setTheme("light"))}
    />
  );
}

export default ThemeSwitch;
