import ParentComp from "../ParentComp";
import RewardsComp from "./RewardsComp";

function Rewards() {
  return (
    <>
      <ParentComp
        title={"المكافآت"}
        prevPage={"الزيادات"}
        linkto={"/addition/"}
      >
        <RewardsComp />
      </ParentComp>
    </>
  );
}

export default Rewards;
