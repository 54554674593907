import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../assets/images/Image";
import Loader from "./Loader";
import { AuthCountext } from "./AuthCountext";
import Icon from "./Icon";
import {
  closeIconDark,
  closeIconLight,
  searchIconDark,
  searchIconLight,
} from "../assets/icons";

function SearchBar() {
  const { theme, SERVER_URL, branchId, userToken } = useContext(AuthCountext);
  const [search, setSearch] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [data, setData] = useState([]);

  const searchFetch = async () => {
    setSearchIsLoading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/search/${searchData}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg === "No data") {
        setData([]);
      } else {
        setData(json);
      }
      setSearchIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    searchFetch();
  };

  return (
    <div
      className="flex flex-column align-start justify-center"
      style={{
        position: "relative",
        height: "40px",
        zIndex: "101",
      }}
    >
      {search ? (
        <div className="flex flex-column justify-start align-ceter media-flex-culomn">
          <div className="flex flex-row justify-start gap-20">
            <div
              className="flex align-center justify-center"
              style={{ cursor: "pointer" }}
            >
              <Icon
                icon={theme === "dark" ? closeIconDark : closeIconLight}
                w={25}
                h={25}
                onClick={(e) => {
                  setSearch(false);
                  setData([]);
                }}
              />
            </div>

            <input
              type="search"
              className={`height-40 search-${theme}`}
              onChange={handleSearch}
              placeholder="ابحث عن موظف"
            />
          </div>
          {data.length > 0 ? (
            <div
              className={`flex flex-column m-r-60 shadow-${theme} p-10 background-div-${theme} width-350 search-list`}
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                position: "absolute",
                top: "40px",
                right: 0,
              }}
            >
              {searchIsLoading ? (
                <Loader size={"medium"} />
              ) : (
                data?.map((item) => (
                  <Link
                    className={`text-decoration hover-background-div-${theme} p-l-10 border-radius p-r-10 color-blue flex flex-row justify-space-between align-center`}
                    to={`/employee/${item.id}`}
                    onClick={() => {
                      setSearch(false);
                      setData([]);
                    }}
                  >
                    <div className="flex flex-row justify-start align-center">
                      <div className="flex flex-row justify-start align-center round over-flow width-50 height-50">
                        <Image
                          image={`${SERVER_URL}/${item.photo}`}
                          w={30}
                          h={30}
                          name={item?.name}
                        />
                      </div>
                      <span className="color-gray">{item.name}</span>
                    </div>
                    <span className="font-small color-gray">{item.title}</span>
                  </Link>
                ))
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="flex flex-column justify-start align-start">
          <div className="flex flex-row justify-start gap-20">
            <div
              className="flex align-center justify-center"
              style={{ cursor: "pointer" }}
            >
              <Icon
                icon={theme === "dark" ? searchIconDark : searchIconLight}
                w={25}
                h={25}
                onClick={() => setSearch(true)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
