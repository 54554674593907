import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";

function PlaceHolder() {
  const { theme } = useContext(AuthCountext);
  return (
    <div
      style={{
        padding: "10px",
        minWidth: "350px",
        maxWidth: '500px',
        height: "150px",
        position: "relative",
        borderRadius: "10px",
        flexGrow: 1
      }}
      className={`shadow-${theme} flex flex-column align-start justify-center background-div-${theme} hover-background-div-${theme} media-full-width`}
    >
      <div
        className={`p-5 flex flex-row align-center justify-end width-10 round place-holder-background-${theme}`}
        style={{
          position: "absolute",
          left: "10px",
          top: "10px",
          height: "20px",
          width: "20px",
        }}
      ></div>
      <div className="p-5 flex flex-row align-center justify-start gap-10">
        <div
          className={`flex flex-row justify-start align-center round shadow-${theme} over-flow place-holder-background-${theme}`}
          style={{
            height: "70px",
            width: "70px",
          }}
        ></div>
        <div className={`flex flex-column align-start justify-end gap-10`}>
          <div
            className={` place-holder-background-${theme}`}
            style={{
              height: "40px",
              width: "250px",
              borderRadius: "5px",
            }}
          ></div>
          <span
            className={` place-holder-background-${theme}`}
            style={{
              height: "20px",
              width: "100px",
              borderRadius: "5px",
            }}
          ></span>
          <span
            className={` place-holder-background-${theme}`}
            style={{
              height: "20px",
              width: "200px",
              borderRadius: "5px",
            }}
          ></span>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          left: "10px",
          bottom: "10px",
        }}
      >
        <div
          style={{
            height: "40px",
            width: "70px",
            borderRadius: "5px",
          }}
          className={`text-center flex justify-center color-${theme} align-center text-decoration place-holder-background-${theme}`}
        ></div>
      </div>
    </div>
  );
}

export default PlaceHolder;
