import React from "react";
import ParentComp from "./ParentComp";
import EmployeesComp from "./EmployeesComp";
import { Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function Employees() {
  const navigate = useNavigate();
  const ParentButton = () => {
    return (
      <Button
        type="primary"
        icon={<UserAddOutlined style={{ color: "#e3e3e3" }} />}
        onClick={() => navigate("/employee/addEmployee")}
        style={{ color: "#e3e3e3" }}
      >
        أضف موظف جديد
      </Button>
    );
  };

  return (
    <>
      <ParentComp
        title={"ادارة الموظفين"}
        prevPage={"الرئيسية"}
        linkto={"/"}
        button={<ParentButton />}
      >
        <EmployeesComp />
      </ParentComp>
    </>
  );
}

export default Employees;
