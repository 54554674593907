import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import { Link } from "react-router-dom";
import Image from "../../assets/images/Image";
import ExpirePlaceHolder from "./ExpirePlaceHolder";

function Expire({ title, info, expireMsg, icon }) {
  const { SERVER_URL, branchId, theme, userToken } = useContext(AuthCountext);
  const [expireId, setExpireId] = useState([]);
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    setIsloading(true);
    const fetchExpireId = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/expire/${info}/${branchId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.msg === "No data") {
          setExpireId([]);
          expireMsg(false);
        } else {
          setExpireId(json);
          expireMsg(true);
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchExpireId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SERVER_URL, branchId, info, userToken]);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      {expireId.length > 0 &&
        (isloading ? (
          <ExpirePlaceHolder />
        ) : (
          <div
            className={`flex flex-column p-20 border-radius shadow-${theme} background-div-${theme} justify-space-between card-media-width height-240 full-width media-full-width`}
            style={{ fontSize: "small" }}
          >
            <div>
              <span style={{ marginInline: "10px" }}>{icon}</span>
              <span
                style={{
                  fontWeight: "bolder",
                  paddingLeft: "10px",
                }}
              >
                {expireId.length}
              </span>

              <span>{title}</span>
            </div>

            <div
              style={{ height: "360px" }}
              className={`over-flow-scroll over-flow-scroll-${theme} card-media-width full-width`}
            >
              {expireId?.map((item) => (
                <Link
                  className={`text-decoration hover-background-div-${theme} before-background-color-${theme} before-text-color-${theme} position-relative over-flow  p-l-10 border-radius p-r-10 color-blue flex flex-row justify-space-between align-center`}
                  to={`/employee/${item.id}`}
                  style={{ marginBlock: "2px" }}
                  key={item.id}
                >
                  <div className="flex flex-row justify-start align-center">
                    <div className="flex flex-row justify-start align-center round over-flow width-50 height-50">
                      <Image
                        image={`${SERVER_URL}/${item.photo}`}
                        w={30}
                        h={30}
                        name={item?.name}
                      />
                    </div>
                    <span className="color-gray">
                      {item.name.slice(0, 15)}
                      {item.name.length > 15 && "..."}
                    </span>
                  </div>
                  <span className="font-small color-gray" style={{ zIndex: 2 }}>
                    {item.idExpireDate
                      ? item.idExpireDate
                      : item.passExpireDate}
                  </span>
                </Link>
              ))}
            </div>
          </div>
        ))}
    </>
  );
}

export default Expire;
