import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";

function EmployeePlaceHolder() {
  const { theme } = useContext(AuthCountext);

  const Place = () => {
    const places = [];
    for (let i = 0; i < 8; i++) {
      places.push(
        <div
          style={{ width: "112px", height: "102px" }}
          className={`place-holder-background-${theme} border-radius`}
        ></div>
      );
    }
    return places;
  };
  return (
    <>
      <div style={{ minWidth: "40%", minHeight: "580px" }}>
        <div className="flex flex-row gap-20 full-width min-width-700 position-relative  media-flex-column">
          <div className="flex flex-column align-start">
            <div
              style={{ width: "220px", height: "290px" }}
              className={`place-holder-background-${theme} border-radius`}
            ></div>
          </div>
          <div className="flex flex-column justify-space-between">
            <div className="flex flex-row" style={{ maxWidth: "200px" }}>
              <div className="width-300">
                <div>
                  <div
                    style={{ width: "70px", height: "30px", marginBlock: '10px' }}
                    className={`place-holder-background-${theme} border-radius`}
                  ></div>
                </div>
                <div
                  style={{ width: "70px", height: "90px" }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              </div>
              <div className="width-300">
                <div>
                  <div
                    style={{ width: "70px", height: "30px",  marginBlock: '10px' }}
                    className={`place-holder-background-${theme} border-radius`}
                  ></div>
                </div>
                <div
                  style={{ width: "70px", height: "90px" }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              </div>
            </div>
            <div>
              <div className="text-align-start">
                <div
                  style={{ width: "200px", height: "40px", marginBlock: '10px' }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              </div>
              <div className="opacity-8 font-large flex flex-row align-center gap-5">
                <div
                  style={{ width: "80px", height: "30px" }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
                |{" "}
                <div
                  style={{ width: "80px", height: "30px" }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
                <div
                  style={{ width: "20px", height: "20px", borderRadius: '50%' }}
                  className={`place-holder-background-${theme}`}
                ></div>
                <div
                  style={{ width: "20px", height: "20px", borderRadius: '50%' }}
                  className={`place-holder-background-${theme}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-column align-start">
          <div
            style={{ width: "128px", height: "40px", marginBlock: '40px' }}
            className={`place-holder-background-${theme} border-radius`}
          ></div>

          <div
            style={{
              alignItems: "self-start",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <Place />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row flex-wrap justify-start text-align-start full-width gap-20 `}
      >
        <div
          className={`flex flex-column align-start justify-start p-10 border-radius`}
          style={{ width: "360px" }}
        >
          <div
            style={{ width: "207px", height: "40px", marginBlock: '21px' }}
            className={`place-holder-background-${theme} border-radius`}
          ></div>

          <ul className="flex flex-column">
            <li className="flex flex-row justify-start gap-10 align-center">
            <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
          </ul>
        </div>

        <div
          className={`flex flex-column align-start justify-start p-10 border-radius`}
          style={{ width: "360px" }}
        >
          <div
            style={{ width: "150px", height: "40px", marginBlock: '21px' }}
            className={`place-holder-background-${theme} border-radius`}
          ></div>

          <ul className="flex flex-column">
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              
                <div
                  style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
             
                <div
                  style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              
            </li>
          </ul>
        </div>

        <div
          className={`flex flex-column align-start justify-start p-10 border-radius`}
          style={{ width: "360px" }}
        >
          <div
            style={{ width: "150px", height: "40px", marginBlock: '21px' }}
            className={`place-holder-background-${theme} border-radius`}
          ></div>

          <ul className="flex flex-column">
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              
                <div
                  style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              
                <div
                  style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
             
            </li>
          </ul>
        </div>

        <div
          className={`flex flex-column align-start justify-start p-10 border-radius`}
          style={{ width: "360px" }}
        >
          <div
            style={{ width: "150px", height: "40px", marginBlock: '21px' }}
            className={`place-holder-background-${theme} border-radius`}
          ></div>

          <ul className="flex flex-column">
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
            </li>
            <li className="flex flex-row justify-start gap-10">
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              <div
                style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                className={`place-holder-background-${theme} border-radius`}
              ></div>
              
                <div
                  style={{ width: "100px", height: "30px", marginBlock: '2px' }}
                  className={`place-holder-background-${theme} border-radius`}
                ></div>
              
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default EmployeePlaceHolder;
