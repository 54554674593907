import React, { useContext } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AuthCountext } from "../component/AuthCountext";
import ThemeSwitch from "./ThemeSwitch";
import ParentComp from "../component/ParentComp";
import CreatUserComp from "./CreatUserComp";

dayjs.extend(customParseFormat);

function CreatUser() {
  const { usersData, theme } = useContext(AuthCountext);

  return usersData ? (
    <div className="flex flex-column full-width">
      <ParentComp
        title={"ادارة المستخدمين"}
        prevPage={"الاعدادات"}
        linkto={"/settings/"}
      >
        <CreatUserComp />
      </ParentComp>
    </div>
  ) : (
    <>
      <h1>اهلا وسهلا بك في نظام الرواتب</h1>
      <div
        className={`flex flex-column text-align-start alert-info shadow-${theme} p-20`}
      >
        <span>
          في البداية عليك انشاء مستخدم واحد على الأقل للتمكن من دخول النظام
        </span>
        <span>ثم عليك في الخطوة التالية اضافة فرع واحد على الأقل</span>
        {usersData === true && <h1>Ruuuuun</h1>}
      </div>
      <ThemeSwitch />
      <CreatUserComp />
    </>
  );
}

export default CreatUser;
