import ParentComp from "./ParentComp";
import SettingComp from "./SettingComp";

function Settings() {
  return (
    <>
      <ParentComp title={"لوحة الإعدادات"}>
        <SettingComp />
      </ParentComp>
    </>
  );
}

export default Settings;
