import React, { useState, useEffect, useContext } from "react";
import Loader from "./Loader";
import { DatePicker, Space } from "antd";
import Alert from "./subcomponent/Alert";
import { Pagination } from "antd";
import Icon from "./Icon";
import { excelIcon, pdfIcon } from "../assets/icons";
import { AuthCountext } from "./AuthCountext";
import UlPlaceHolder from "./subcomponent/UlPlaceHolder";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;

const SalaryComp = () => {
    const { SERVER_URL, branchId, theme, userToken } = useContext(AuthCountext);
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [alertMsg, setAlertMsg] = useState(null);
    const [alertName, setAlertName] = useState(null);
    const [isAlert, setIsAlert] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataInPage, setDataInPage] = useState(7);
    const [total, setTotal] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [isLoadingExcel, setIsLoadingExcel] = useState(false);

    const [addButtonLoading, setAddButtonLoading] = useState(false);

    const [formData, setFormData] = useState({
      startdate: null,
      enddate: null,
      month: null,
      date: null,
      branchId: branchId,
    });

    let fetchUrl = "";
    if (month === null) {
      fetchUrl = `${SERVER_URL}/fecthsalary/${currentPage}/${dataInPage}/${branchId}`;
    } else {
      fetchUrl = `${SERVER_URL}/fecthsalary/${year}/${month}/${currentPage}/${dataInPage}/${branchId}`;
    }

    const issueSalary = async () => {
      setAddButtonLoading(true);
      setDisable(true);
      try {
        const response = await fetch(`${SERVER_URL}/salaryessuing`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
          body: JSON.stringify(formData),
        });
        const json = await response.json();
        console.log(json);
        setIsAlert(true);
        if (json.msg === "Salary added!") {
          setAlertMsg("تم اصدار الرواتب نجاح");
          setAlertName("alert-success");
        }
        if (json.msg === "Salary exist!") {
          setAlertMsg("تم اصدار الرواتب من قبل");
          setAlertName("alert-info");
        }
        alertShow();
        setAddButtonLoading(false);
        setDisable(false);
        // fetchData();
      } catch (error) {
        console.error(error);
      }
    };

    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(fetchUrl, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        });
        const json = await response.json();
        // console.log(json.msg);
        if (json.msg !== "No data") {
          setData(json.data);
          setTotal(json.total);
        } else {
          setData([]);
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPdfData = async () => {
      setIsLoadingPdf(true);
      try {
        const response = await fetch(
          `${SERVER_URL}/pdf/${year}/${month}/${branchId}`,
          {
            method: "GET",
          }
        );
        const json = await response.blob();
        // console.log(json)
        const pdf = new Blob([json]);

        const f = new File([pdf], "my.pdf", { type: "application/pdf" });
        // console.log(f)
        const urlpdf = URL.createObjectURL(f);
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.href = urlpdf;
        document.body.appendChild(link);
        link.click();
        setIsLoadingPdf(false);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchExcelData = async () => {
      setIsLoadingExcel(true);
      try {
        const response = await fetch(
          `${SERVER_URL}/excel/${year}/${month}/${branchId}`,
          {
            method: "GET",
          }
        );
        const json = await response.blob();
        // console.log(json)
        const excel = new Blob([json]);
        // console.log(excel)
        const f = new File([excel], `salaryForMonth-${month}-${year}`, {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // console.log(f)
        const urlexcel = URL.createObjectURL(f);
        const link = document.createElement("a");
        link.setAttribute("download", f.name);
        link.href = urlexcel;
        document.body.appendChild(link);
        link.click();
        setIsLoadingExcel(false);
      } catch (error) {
        console.error(error);
      }
    };

    const alertShow = () => {
      const timer = setTimeout(() => setIsAlert(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    };

    useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, dataInPage, fetchUrl, month]);

    const loader = () => {
      const timer = setTimeout(() => setIsloading(false), 1000);
      return () => {
        clearTimeout(timer);
      };
    };

    useEffect(() => {
      loader();
    }, []);

    const validationFun = () => {
      if (
        formData.startdate !== null &&
        formData.enddate !== null &&
        formData.month !== null &&
        formData.date !== null
      ) {
        setDisable(true);
        issueSalary();
      } else {
        console.log("no valid");
        setIsAlert(true);
        setAlertMsg("ادخل جميع الحقول");
        setAlertName("alert-warning");
        alertShow();
      }
    };

    // console.log(formData)

    function handleSubmit(event) {
      event.preventDefault();
      console.log(formData);
      validationFun();
    }

    const Place = () => {
      const places = [];
      for (let i = 0; i < dataInPage; i++) {
        places.push(<UlPlaceHolder />);
      }
      return places;
    };

    return (
      <div
        className={`flex justify-center align-center background-${theme} color-${theme} card-media-width full-width`}
      >
        <div
          className={`${
            isloading
              ? "flex flex-row justify-center align-center "
              : " justify-space-between "
          } flex flex-row justify-center media-flex-culomn tablet-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-start background-div-${theme} full-width min-height-300 p-20`}
        >
          <div className="full-width" style={{ minHeight: "580px" }}>
            <div className="flex flex-column-reverse justify-space-between align-start">
              {isAlert && <Alert msg={alertMsg} alert={alertName} />}
              <div className="flex flex-row justify-start align-start gap-5 height-60 media-flex-culomn tablet-flex-culomn" style={{height: 'auto'}}>
                <span
                  className="flex font-small font-500 min-width-150 align-center justify-start p-r-10 height-40"
                  style={{ borderRight: "4px solid dodgerblue" }}
                >
                  عرض كشف شهر
                </span>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size={"large"}
                >
                  <DatePicker
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "10px",
                    }}
                    size={"large"}
                    placeholder="اختر الشهر"
                    picker="month"
                    onChange={(date) => {
                      setCurrentPage(1);
                      if (date) {
                        setMonth(date.$M !== null && date.$M + 1);
                        setYear(date.$y !== null && date.$y);
                      } else {
                        setMonth(null);
                        setYear(null);
                        setCurrentPage(1);
                        setIsLoadingExcel(false);
                        setIsLoadingPdf(false);
                      }
                    }}
                  />
                </Space>
                {month !== null && (
                  <div className="flex flex-row justify-space-between gap-20 ">
                    <div
                      className="flex justify-space-around align-center height-40 min-width-100 border-radius border-1-dark cursor-pointer excel-button"
                      onClick={fetchExcelData}
                    >
                      <>
                        <span style={{ color: "#fff" }}>اكسل</span>
                        {isLoadingExcel ? (
                          <Loader size={"medium"} color={"white"} />
                        ) : (
                          <Icon icon={excelIcon} w={30} h={30} />
                        )}
                      </>
                    </div>
                    <div
                      className="flex justify-space-around align-center height-40 min-width-100 border-radius border-1-dark cursor-pointer pdf-button"
                      onClick={fetchPdfData}
                    >
                      <>
                        <span
                          className="color-dark"
                          style={{ fontFamily: "Roboto", fontWeight: "500" }}
                        >
                          PDF
                        </span>
                        {isLoadingPdf ? (
                          <Loader size={"medium"} color={"white"} />
                        ) : (
                          <Icon icon={pdfIcon} w={30} h={30} />
                        )}
                      </>
                    </div>
                  </div>
                )}
              </div>
              <form
                className="flex flex-row justify-start align-start gap-20 media-flex-column"
                onSubmit={handleSubmit}
              >
                <div className="font-small font-500 min-width-150 text-align-start">
                  <span
                    className="flex font-small font-500 min-width-150 align-center justify-start p-r-10 height-40 card-media-width"
                    style={{ borderRight: "4px solid dodgerblue" }}
                  >
                    اصدار الرواتب
                  </span>
                </div>
                <div className="flex flex-row justify-start align-center gap-20 media-flex-column">
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={"large"}
                  >
                    <RangePicker
                      onChange={(date, dateString) =>
                        setFormData((prevFormData) => {
                          return {
                            ...prevFormData,
                            startdate: dateString[0],
                            enddate: dateString[1],
                          };
                        })
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                      size="large"
                      placeholder={["بداية الفترة", "نهاية الفترة"]}
                    />
                  </Space>
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={"large"}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                      size={"large"}
                      placeholder="الشهر"
                      picker="month"
                      onChange={(date, dateString) => {
                        setFormData((prevFormData) => {
                          return {
                            ...prevFormData,
                            month: dateString,
                          };
                        });
                      }}
                    />
                  </Space>
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={"large"}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                      size={"large"}
                      placeholder="التاريخ"
                      onChange={(date, dateString) =>
                        setFormData((prevFormData) => {
                          return {
                            ...prevFormData,
                            date: dateString,
                          };
                        })
                      }
                    />
                  </Space>
                  <button
                    className={`next-button-${theme} shadow-${theme} font-small min-width-100 height-40 shadow-${theme} ${
                      disable && "disabled"
                    } ${
                      addButtonLoading &&
                      "flex flex-row justify-space-around align-center"
                    }`}
                    disabled={disable}
                  >
                    {addButtonLoading && (
                      <Loader size={"medium"} color={"white"} />
                    )}
                    اصدار الرواتب
                  </button>
                </div>
              </form>
            </div>
            <div
              className={`over-flow-scroll over-flow-scroll-${theme}`}
              style={{ overflow: 'scroll', width: '100%', minHeight: '500px'}}
            >
              <ul
                className={`border-bottom-${theme}-3 font-bold`}
                style={{ minWidth: "1275px" }}
              >
                <li
                  className="p-5 flex flex-row align-center justify-start width-5-100"
                  style={{ minWidth: "50px" }}
                >
                  الرقم
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-40-100"
                  style={{ minWidth: "250px" }}
                >
                  الإسم
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "100px" }}
                >
                  الراتب الاساسي
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  العلاوة
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  الإضافي
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  الحوافز
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  المكافآت
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  الخصم
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  الغياب
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  السلف
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  إجازة
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  الصافي
                </li>
                <li
                  className="p-5 flex flex-row align-center justify-start width-10"
                  style={{ minWidth: "75px" }}
                >
                  الشهر
                </li>
              </ul>
              {isloading ? (
                <Place />
              ) : data.length > 0 ? (
                data?.map((item) => (
                  <ul
                    className={`border-bottom-${theme}-1`}
                    key={item.id}
                    style={{ minWidth: "1275px", height: "60px" }}
                  >
                    <li
                      className="p-5 flex flex-row align-center justify-start width-5-100"
                      style={{ minWidth: "50px" }}
                    >
                      {item.id}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-40-100 gap-10"
                      style={{ minWidth: "250px" }}
                    >
                      <Link
                        className={`color-${theme} text-decoration hover-background-div-${theme}`}
                        to={`/employee/${item.employeeId}`}
                      >
                        <div>{item.name}</div>
                      </Link>
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "100px" }}
                    >
                      {item.salary}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.increase === "0.00" ? "" : item.increase}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.overtime === "0.00" ? "" : item.overtime}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.incentives}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.rewards}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.cut}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.absence}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.advace === "0.00" ? "" : item.advace}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.vacation === "0" ? "" : item.vacation}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.amount}
                    </li>
                    <li
                      className="p-5 flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "75px" }}
                    >
                      {item.month}
                    </li>
                  </ul>
                ))
              ) : (
                <h1>لا توجد بيانات</h1>
              )}
            </div>
            <div className="flex flex-row p-20">
              <Pagination
                defaultCurrent={currentPage}
                total={total}
                pageSize={dataInPage}
                showQuickJumper
                showSizeChanger
                onChange={(page, pageSize) => {
                  setDataInPage(pageSize);
                  setCurrentPage(page);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };


export default SalaryComp