import React from "react";

function Alert({msg, alert}) {
  return (
    <div className={alert}>
      <span>{msg}</span>
    </div>
  );
}

export default Alert;
