import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import { Upload, message } from "antd";

function UploadImage({ data, setData, name, placeholder, image, icon, listType }) {
  const { SERVER_URL, userToken } = useContext(AuthCountext);
  const [uploadData, setUploadData] = useState(image || null);

  // function handleUpload(file, name) {
  //   setloading();
  //   if (!file) {
  //     console.log("No files");
  //     return;
  //   }
  //   const fd = new FormData();
  //   fd.append("file", file);
  //   fd.append("id", "employeeId");
  //   fd.append("image", name);

  //   fetch(`${SERVER_URL}/uploaddocumnets/`, {
  //     method: "POST",
  //     headers: {
  //       "Custom-Header": "value",
  //       "x-api-key": userToken,
  //     },
  //     body: fd,
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error("Bad response");
  //       }
  //       console.log("Upload successful");
  //       return res.json();
  //     })
  //     .then((d) => setUploadData(d.file))
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   loader();
  // }

  const deletePhoto = (file, image) => {
    fetch(`${SERVER_URL}/uploaddocumnets`, {
      method: "DELETE",
      headers: {
        "Custom-Header": "value",
        "x-api-key": userToken,
      },
      body: JSON.stringify({ file }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Bad response");
        }
        console.log("Upload successful");
        return res.json();
      })
      .then((data) => console.log(data))
      .catch((err) => {
        console.log(err);
      });
    setData((prevData) => {
      return {
        ...prevData,
        [name]: null,
      };
    });
  };

  // const unSetloading = () => {
  //   setIsloading(false);
  //   setIsdisable(false);
  //   setPhoto(null);
  // };

  // const handleUploadPhoto = (e) => {
  //   setPhoto(e.target.files[0]);
  //   setPhotoUploadbutton(true);
  //   setIsdisable(false);
  // };

  useEffect(() => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: uploadData,
      };
    });
  }, [uploadData, setData, name]);

  const props = {
    name: "file",
    data: {
      id: data.name,
      image: name,
    },
    action: `${SERVER_URL}/uploaddocumnets`,
    headers: {
      "Custom-Header": "value",
      "x-api-key": userToken,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadData(info.fileList[0].response.file);
        console.log(info.fileList[0].response.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload
      maxCount={1}
      {...props}
      listType={listType}
      onRemove={() => {
        console.log(image);
        deletePhoto(image, "image");
      }}
    >
      {placeholder}
    </Upload>
  );
}

export default UploadImage;
