import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";
import InfoDivPlaceHolder from "./InfoDivPlaceHolder";
import { Progress } from "antd";
import CountUp from "react-countup";

function InfoDiv({ list, isloading }) {
  const { theme } = useContext(AuthCountext);
  const formatter = (value) => <CountUp end={value} separator="" />;
  return (
    <>
      {list.map((item, index) => (
        <div
          className={`text-decoration color-${theme} scale-hover-1 media-full-width`}
          style={{ fontSize: "xx-small", flexGrow: "inherit" }}
          key={index}
        >
          <div
            key={item.title}
            className={`flex flex-row p-20 border-radius shadow-${theme} background-div-${theme} position-relative over-flow justify-space-between align-center media-full-width`}
            style={{ height: "110px" }}
          >
            {isloading ? (
              <InfoDivPlaceHolder />
            ) : (
              <>
                <div className="flex flex-column" style={{ zIndex: "1" }}>
                  <div className="flex flex-rwo gap-10"  style={{ fontSize: "14px", fontWeight: "700" }}>
                    {item.icon}
                    <span>
                      {item.title}
                    </span>
                  </div>
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                   {formatter(item.result && item.result)}
                  </span>
                </div>
                <div
                  className={`flex flex-row justify-space-between align-center position-relative`}
                >
                  {(item?.x || item?.y) && (
                    <div className="flex flex-row media-flex-column justify-center align-center">
                      <div
                        className={`flex flex-column justify-center align-center text-align-center color-${theme} before-text-color-${theme}`}
                        style={{ width: "50px", height: "50px" }}
                      >
                        <Progress
                          type="circle"
                          size="small"
                          percent={
                            (item.result /
                              item.y.reduce(
                                (a, b) => Number(a) + Number(b),
                                0
                              )) *
                            50
                          }
                          format={() => formatter(`${item.result}`)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default InfoDiv;
