import React, { useState, useEffect, useContext } from "react";
import Loader from "./Loader";
import StepOne from "./steps/StepOne";
import StepTow from "./steps/StepTow";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import StepFive from "./steps/StepFive";
import { AuthCountext } from "./AuthCountext";
import Alert from "./subcomponent/Alert";
import { Button, Steps } from "antd";

const AddEmployeeComp = () => {
  const { SERVER_URL, theme, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [disable, setDisable] = useState(true);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [formData, setFormData] = useState({
    name: null,
    phone: null,
    email: null,
    birthdate: null,
    sex: null,
    fulltime: null,
    employee: null,
    idNumber: null,
    countrycode: null,
    idExpireDate: null,
    passportNumber: null,
    passExpireDate: null,
    photo: null,
    idcopy: null,
    passport: null,
    positionId: null,
    startInPosition: null,
    endInPosition: null,
    amount: null,
    paymethodId: null,
    start: null,
    end: null,
  });

  const resetFormData = () => {
    setFormData((prevFormData) => {
      return {
        name: null,
        phone: null,
        email: null,
        birthdate: null,
        sex: null,
        fulltime: null,
        employee: null,
        idNumber: null,
        countrycode: null,
        idExpireDate: null,
        passportNumber: null,
        passExpireDate: null,
        photo: null,
        idcopy: null,
        passport: null,
        positionId: null,
        startInPosition: null,
        endInPosition: null,
        amount: null,
        paymethodId: null,
      };
    });
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const steps = [
    {
      title: "بيانات الموظف",
      step: (
        <StepOne
          currentStep={currentStep}
          stepLenght={5}
          formData={formData}
          setFormData={setFormData}
          setDisable={setDisable}
        />
      ),
    },
    {
      title: "المستندات الثبوتية",
      step: (
        <StepTow
          currentStep={currentStep}
          stepLenght={5}
          formData={formData}
          setFormData={setFormData}
          setDisable={setDisable}
        />
      ),
    },
    {
      title: "نسخة من المستندات",
      step: (
        <StepThree
          currentStep={currentStep}
          stepLenght={5}
          formData={formData}
          setFormData={setFormData}
          setDisable={setDisable}
        />
      ),
    },
    {
      title: "بيانات العقد",
      step: (
        <StepFour
          currentStep={currentStep}
          stepLenght={5}
          formData={formData}
          setFormData={setFormData}
          setDisable={setDisable}
        />
      ),
    },
    {
      title: "الإنهاء",
      step: (
        <StepFive
          currentStep={currentStep}
          stepLenght={5}
          formData={formData}
          setFormData={setFormData}
          setDisable={setDisable}
        />
      ),
    },
  ];

  const sendData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/adEmployees/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg(`تمت اضافة الموظف ${formData.name} بنجاح`);
      setAlertName("alert-success");
      alertShow();
      resetFormData();
      finishSteps();
      loader();
    } catch (error) {
      console.error(error);
      setIsAlert(true);
      setAlertMsg("حدث خطأ ما..");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    loader();
  }, []);

  const nextStep = () => {
    setNextButtonLoading(true);
    setCurrentStep(currentStep + 1);
    setDisable(true);
    loader();
    setNextButtonLoading(false);
  };

  const finishSteps = () => {
    const timer = setTimeout(() => setCurrentStep(0), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const stepsAction = (currentStep) => {
    return steps[currentStep].step;
  };

  console.log(formData);

  return (
    <div
      className={`flex flex-column gap-20 justify-center align-center background-${theme} color-${theme} full-width`}
    >
      <Steps
        className={`background-${theme} color-${theme}`}
        size="small"
        current={currentStep}
        items={steps}
      />
      {isAlert && <Alert msg={alertMsg} alert={alertName} />}
      <div
        className={`flex flex-column align-center ${isloading ? `justify-center` : `justify-space-between` } shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} card-media-width p-10`}
        style={{ width: "50%", marginBlock: "20px", height: '600px', paddingTop: '20px' }}
      >
        {isloading ? (
          <Loader />
        ) : (
          <>
            {stepsAction(currentStep)}
            <div
              className="flex flex-row justify-center align-center"
              style={{ marginBottom: "20px", minWidth: '70%' }}
            >
              {currentStep === steps.length - 1 ? (
                <Button
                  type="primary"
                  className={`full-width`}
                  disabled={disable}
                  loading={nextButtonLoading}
                  onClick={() => {
                    sendData();
                  }}
                  style={{height: '50px'}}
                >
                  انهاء
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={nextStep}
                  disabled={disable}
                  className={`full-width`}
                  loading={nextButtonLoading}
                  style={{height: '50px'}}
                >
                  التالي
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddEmployeeComp;
