import React from 'react'

function InfoDivPlaceHolder() {
  return (
    <div>
      
    </div>
  )
}

export default InfoDivPlaceHolder
