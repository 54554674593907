import AdditionComp from "./AdditionComp";
import ParentComp from "./ParentComp";

function Addition() {
  return (
    <>
      <ParentComp title={"الزيادات"}>
        <AdditionComp />
      </ParentComp>
    </>
  );
}

export default Addition;
