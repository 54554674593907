import React, { useEffect, useState, useContext } from "react";
import { AuthCountext } from "../AuthCountext";
import Loader from "../Loader";
import { Link } from "react-router-dom";

function EmployeeInfo({ id, info, name, kind }) {
  const { SERVER_URL, userToken, theme } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(`${SERVER_URL}/info/${id}/${info}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        });
        const json = await response.json();
        setData(json);
        setIsloading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SERVER_URL, info, id, userToken]);

  return (
    <Link
      className={`over-flow position-relative text-decoration`}
      to={`../${info}/${id}`}
    >
      <div
        className={`shadow-${theme} color-${theme} before-text-color-${theme} before-background-color-${theme} position-relative border-radius flex flex-column align-center justify-center p-20 gap-10 border-primary-${theme} over-flow ${
          data > 0
            ? `bgcolor-primary-${theme}  ${
                theme === "dark" ? "color-light" : "color-dark"
              }`
            : `'trasparent'`
        }`}
        style={{ zIndex: 2 }}
      >
        <div className={`font-bold ${data > 0
            ? `bgcolor-primary-${theme}  ${
                theme === "dark" ? "color-light" : "color-dark"
              }`
            : `'trasparent'`
        }`} style={{ zIndex: 2 }}>
          {name}
        </div>

        <div
          className={`flex flex-row align-center justify-space-between width-70 text-align-center over-flow ${data > 0
            ? `bgcolor-primary-${theme}  ${
                theme === "dark" ? "color-light" : "color-dark"
              }`
            : `'trasparent'`
        }`}
          style={{ zIndex: 2 }}
        >
          <span className="flex flex-row align-center jusify-center over-flow">
            {isloading ? <Loader size={"small"} /> : data ? data : "0"}
          </span>
          <span>{kind}</span>
        </div>
      </div>
    </Link>
  );
}

export default EmployeeInfo;
