import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";
import ThemeSwitch from "../../auth/ThemeSwitch";
import ParentComp from "../ParentComp";
import BranchComp from "./BranchComp";

function Branch() {
  const { theme, branchData } = useContext(AuthCountext);

  return branchData ? (
    <div className="flex flex-column full-width">
      <ParentComp title={"الفروع"} prevPage={"الاعدادات"} linkto={"/settings/"}>
        <BranchComp />
      </ParentComp>
    </div>
  ) : (
    <div className="full-width">
      <h1>اهلا وسهلا بك في نظام الرواتب</h1>
      <div
        className={`flex flex-column text-align-start alert-info shadow-${theme} p-20`}
      >
        <span>الآن قم بإنشاء أول فرع</span>
        <span>ثم حدث الصفحة</span>
      </div>
      <ThemeSwitch />
      <BranchComp />
    </div>
  );
  
  // return (
  //   <div
  //     className={
  //       !branchData &&
  //       `flex flex-row justify-center align-center full-width background-${theme} color-${theme}`
  //     }
  //   >
  //     <div
  //       className={
  //         !branchData &&
  //         "flex flex-column justify-center align-center gap-20 p-20 full-width"
  //       }
  //     >
  //       {branchData ? (
  //         <div className="full-width">
  //           <ParentComp
  //             title={"الفروع"}
  //             prevPage={"الاعدادات"}
  //             linkto={"/settings/"}
  //           >
  //             <BranchComp />
  //           </ParentComp>
  //         </div>
  //       ) : (
  //         <div className="full-width">
  //           <h1>اهلا وسهلا بك في نظام الرواتب</h1>
  //           <div
  //             className={`flex flex-column text-align-start alert-info shadow-${theme} p-20`}
  //           >
  //             <span>الآن قم بإنشاء أول فرع</span>
  //             <span>ثم حدث الصفحة</span>
  //           </div>
  //           <ThemeSwitch />
  //           <BranchComp />
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
}

export default Branch;
