import ParentComp from "../ParentComp";
import PositionTitleComp from "./PositionTitleComp";

function PositionTitle() {
  return (
    <>
      <ParentComp
        title={"قائمة الوظائف"}
        prevPage={"الاعدادات"}
        linkto={"/settings/"}
      >
        <PositionTitleComp />
      </ParentComp>
    </>
  );
}

export default PositionTitle;
