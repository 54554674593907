import React, { useContext, useEffect, useState } from "react";
import Icon from "./Icon";
import {
  dashboard,
  employee,
  payment,
  salary,
  salaryCut,
  settings,
  vacation,
  economy,
  editBranchdark,
  editBranchlight,
} from "../assets/icons";
import { Link, NavLink } from "react-router-dom";
import { AuthCountext } from "./AuthCountext";
import Image from "../assets/images/Image";
import Loader from "./Loader";
import {
  CalculatorOutlined,
  DollarOutlined,
  FallOutlined,
  HomeOutlined,
  RightOutlined,
  RiseOutlined,
  SettingOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import WindowSize from "./subcomponent/WindowSize";
import { Tooltip } from "antd";

function RightBar() {
  const {
    userAdmin,
    branchId,
    theme,
    SERVER_URL,
    userToken,
    openRightBar,
    setOpenRightBar,
  } = useContext(AuthCountext);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [menu, setMenu] = useState(true);

  const windowSize = WindowSize();

  const list = [
    {
      title: "لوحة البيانات",
      icon: dashboard,
      link: "/dashboard",
      newIcon: <HomeOutlined />,
      auth: '0'
    },
    {
      title: "ادارة الموظفين",
      icon: employee,
      link: "/employee",
      newIcon: <TeamOutlined />,
      auth: '0'
    },
    {
      title: "اصدار الرواتب",
      icon: salary,
      link: "/salary",
      newIcon: <DollarOutlined />,
      auth: '0'
    },
    {
      title: "الخصومات",
      icon: salaryCut,
      link: "/salarycut",
      newIcon: <FallOutlined />,
      auth: '0'
    },
    {
      title: "الاجازات",
      icon: vacation,
      link: "/vacation",
      newIcon: <UserSwitchOutlined />,
      auth: '0'
    },
    {
      title: "السلف",
      icon: payment,
      link: "/advancepayment",
      newIcon: <CalculatorOutlined />,
      auth: '0'
    },
    {
      title: "الزيادات",
      icon: economy,
      link: "/addition",
      newIcon: <RiseOutlined />,
      auth: '0'
    },
    {
      title: "الاعدادات",
      icon: settings,
      link: "/settings",
      newIcon: <SettingOutlined />,
      auth: '1'
    },
  ];

  const fetchBranch = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setData(json);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  return (
    <div
      style={
        windowSize[0] < 900
          ? openRightBar
            ? { display: "flex", zIndex: 105 }
            : { display: "none" }
          : menu
          ? windowSize[0] < 1200
            ? { width: "25%" }
            : { width: "15%" }
          : { width: "70px" }
      }
      className={`menu shadow-${theme} border-left-${theme}-1 text-align-center background-div-${theme} color-${theme} media-position-fixed full-height transition over-flow-scroll`}
    >
      <div className="menu-container flex flex-column p-t-10 right-bar position-relative">
        {windowSize[0] > 900 && (
          <div
            style={{
              position: "absolute",
              left: "0px",
              top: "20px",
              fontSize: "large",
              cursor: "pointer",
              zIndex: 250,
              width: "30px",
              height: "30px",
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
            className={`flex justify-center align-center shadow-${theme} background-${theme} color-${theme}`}
            onClick={() => setMenu(!menu)}
          >
            <Tooltip title="تصغير القائمة">
              <RightOutlined
                style={{
                  transition: "all ease-in-out 0.3s",
                  rotate: !menu ? "180deg" : "0deg",
                }}
              />
            </Tooltip>
          </div>
        )}

        <div
          className={`flex flex-column justify-center align-center align-text-center p-10 position-relative border-bottom-${theme}-1 media-p`}
          style={{ height: "220px" }}
        >
          {isloading ? (
            openRightBar || !menu ? (
              <Loader size={"small"} />
            ) : (
              <Loader size={"large"} />
            )
          ) : (
            data.map((item, index) => (
              <div
                className={`flex flex-column justify-center align-center align-text-center media-width`}
                style={openRightBar || !menu ? { marginTop: "40px" } : {}}
                key={index}
              >
                {menu &&
                  (openRightBar ? (
                    <Image
                      image={`${SERVER_URL}/${item.logo}`}
                      w={"auto"}
                      h={"50"}
                      name={item.title}
                    />
                  ) : (
                    <Image
                      image={`${SERVER_URL}/${item.logo}`}
                      w={"auto"}
                      h={"50"}
                      name={item.title}
                    />
                  ))}
                <h2
                  style={{
                    transition: "all ease-in-out 0.3s",
                    fontSize: menu ? "large" : "small",
                  }}
                  className={`color-${theme} h1-media-font-size media-width`}
                >
                  {item.title}
                </h2>
                <div
                  className="flex flex-row justify-center align-center align-text-center"
                  style={{ position: "absolute", left: "5px", bottom: "5px" }}
                >
                  <Tooltip title="تغيير الفرع">
                    <Link
                      to={"/branches"}
                      className={`text-center flex justify-center text-decoration color-${theme} gap-5`}
                      style={{
                        transition: "all ease-in-out 0.3s",
                        alignItems: "end",
                      }}
                      onClick={() => setOpenRightBar(false)}
                    >
                      <span
                        style={{
                          fontSize: "10px",
                          transition: "all ease-in-out 0.3s",
                          translate: !menu ? "150px" : "0px",
                        }}
                      >
                        تغيير الفرع
                      </span>

                      <Icon
                        icon={
                          theme === "dark" ? editBranchdark : editBranchlight
                        }
                        w={20}
                        h={20}
                      />
                    </Link>
                  </Tooltip>
                </div>
              </div>
            ))
          )}
        </div>
        <div
          className={`flex flex-column justify-start align-start position-relative text-align-start p-10 border-bottom-${theme}-1`}
        >
          {list.map((item, index) => (
            ((item.auth === userAdmin || Number(item.auth) === userAdmin) || (userAdmin === '1' || userAdmin === 1)) && (
              <Tooltip title={item.title} key={index}>
              <NavLink
                to={item.link}
                key={index}
                className={({ isActive, isPending }) =>
                  isPending
                    ? `text-decoration full-width position-relative border-radius pending-${theme} `
                    : isActive
                    ? `text-decoration full-width position-relative border-radius active-${theme} `
                    : `text-decoration full-width position-relative border-radius color-${theme} `
                }
                style={{ zIndex: 2, overflow: "hidden", marginBlock: "1px" }}
                onClick={() => setOpenRightBar(false)}
              >
                <div
                  style={{
                    minHeight: "50px",
                    fontWeight: "500",
                    fontSize: "0.8rem",
                    margin: "0",
                    transition: "all ease-in-out 0.3s",
                  }}
                  className={`
                            before-background-color-${theme} 
                            before-text-color-${theme} 
                            hover-background-div-${theme} 
                            cursor-pointer full-width 
                            border-radius 
                            flex 
                            flex-row
                            align-center 
                            gap-10 over-flow 
                            position-abolute
                            `}
                >
                  <div
                    style={{
                      transition: "all ease-in-out 0.3s",
                      zIndex: 2,
                      overflow: "hidden",
                      paddingRight: menu ? "10px" : "15px",
                    }}
                  >
                    {item.newIcon}
                  </div>

                  <span
                    style={{
                      transition: "all ease-in-out 0.3s",
                      zIndex: 4,
                      overflow: "hidden",
                      translate: menu ? "-35px" : "-150px",
                      position: "absolute",
                    }}
                  >
                    {item.title}
                  </span>
                </div>
              </NavLink>
            </Tooltip>
            )
          ))}
        </div>
      </div>
    </div>
  );
}

export default RightBar;
