import ParentComp from "./ParentComp";
import VacationComp from "./subcomponent/VacationComp";

function Vacations() {
  

  return (
    <>
      <ParentComp
        title={"الاجازات"}
        prevPage={"الرئيسية"}
        linkto={"/"}
      >
        <VacationComp />
      </ParentComp>
    </>
  );
}

export default Vacations;
