import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import { Select } from "antd";

function FetchAdvancePaymen({ setData, id, defaultValue }) {
  const { SERVER_URL, branchId, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [advanceList, setAdvanceList] = useState([])

  const fetchAdvancePaymenData = async (id) => {
    setAdvanceList([]);
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/advance/${id}/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      console.log(json);
      if (json.length > 0) {
        setAdvanceList(json);
        
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdvancePaymenData(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <Select
      name="advancePaymentId"
      showSearch
      className={`full-width height-50 border-radius`}
      onChange={(e) =>
        setData((prevData) => {
          return {
            ...prevData,
            advancePaymentId: e,
          };
        })
      }
      optionFilterProp="label"
      placeholder={isloading ? "جارٍ التحميل" : "السلفة"}
      defaultValue={defaultValue}
      options={advanceList.map((item) => ({
        value: item.id,
        label: `${item.id} - ${item.amount}`,
      }))}
    />
  );
}

export default FetchAdvancePaymen;
