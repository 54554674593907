import AdvancePaymentComp from "./AdvancePaymentComp";
import ParentComp from "./ParentComp";

function AdvancePayment() {
  return (
    <>
      <ParentComp title={"السلف"}>
        <AdvancePaymentComp />
      </ParentComp>
    </>
  );
}

export default AdvancePayment;
