import { Button } from "antd";
import ParentComp from "../ParentComp";
import MainDocsComp from "./MainDocsComp";
import { FileAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function MainDocs() {
  const navigate = useNavigate();
  const DocsRecordsButton = () => {
    return (
      <Button
        type="primary"
        icon={<FileAddOutlined style={{ color: "#e3e3e3" }} />}
        onClick={() => navigate("maindocsrecords")}
        style={{ color: "#e3e3e3" }}
      >
        أضف مستند جديد
      </Button>
    );
  };
  return (
    <>
      <ParentComp
        title={"قائمة المستندات الثبوتية"}
        prevPage={"الاعدادات"}
        linkto={"/settings/"}
        button={<DocsRecordsButton />}
      >
        <MainDocsComp />
      </ParentComp>
    </>
  );
}

export default MainDocs;
