import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Image from "../assets/images/Image";
import Loader from "./Loader";
import { AuthCountext } from "./AuthCountext";

import Search from "antd/es/input/Search";
import { FullscreenExitOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

function SearchPage() {
  const navigate = useNavigate();
  const { theme, SERVER_URL, branchId, userToken } = useContext(AuthCountext);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [data, setData] = useState([]);

  const searchFetch = async () => {
    setSearchIsLoading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/search/${searchData}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg === "No data") {
        setData([]);
      } else {
        setData(json);
      }
      setSearchIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    searchFetch();
  };

  return (
    <div
      className="flex flex-column align-center justify-center full-width p-10"
      style={{ paddingTop: "100px" }}
    >
      <Tooltip title="الخروج من البحث">
        <FullscreenExitOutlined
          style={{
            fontSize: "large",
            marginBlock: "20px",
            marginInline: "10px",
          }}
          onClick={() => navigate(-1)}
        />
        <span>الخروج</span>
      </Tooltip>
      <Search
        placeholder="ابحث عن موظف بالإسم"
        allowClear
        enterButton="أبحث"
        size="large"
        onChange={handleSearch}
        onAbort={() => setSearchData()}
      />
      {searchData && data.length > 0 ? (
        <div
          className={`flex flex-column shadow-${theme} p-10 background-div-${theme} full-width`}
        >
          {searchIsLoading ? (
            <Loader size={"medium"} />
          ) : (
            data?.map((item) => (
              <Link
                className={`text-decoration hover-background-div-${theme} p-l-10 border-radius p-r-10 color-blue flex flex-row justify-space-between align-center`}
                to={`/employee/${item.id}`}
                onClick={() => {
                  setData([]);
                }}
              >
                <div className="flex flex-row justify-start align-center">
                  <div className="flex flex-row justify-start align-center round over-flow width-50 height-50">
                    <Image
                      image={`${SERVER_URL}/${item.photo}`}
                      w={30}
                      h={30}
                      name={item?.name}
                    />
                  </div>
                  <span className="color-gray">{item.name}</span>
                </div>
                <span className="font-small color-gray">{item.title}</span>
              </Link>
            ))
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SearchPage;
