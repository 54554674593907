import ParentComp from "../ParentComp";
import IncentivesComp from "./IncentivesComp";

function Incentives() {
  return (
    <>
      <ParentComp title={"الحوافز"} prevPage={"الزيادات"} linkto={"/addition/"}>
        <IncentivesComp />
      </ParentComp>
    </>
  );
}

export default Incentives;
