import ParentComp from "../ParentComp";
import OvertimeComp from "./OvertimeComp";

function Overtiem() {
  return (
    <>
      <ParentComp title={"الاضافي"} prevPage={"الزيادات"} linkto={"/addition/"}>
        <OvertimeComp />
      </ParentComp>
    </>
  );
}

export default Overtiem;
