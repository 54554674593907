import ParentComp from "../ParentComp";
import CutComp from "./CutComp";

function SalaryCut() {
  return (
    <ParentComp title={"الخصم"} prevPage={"الخصومات"} linkto={"/salarycut/"}>
      <CutComp />
    </ParentComp>
  );
}

export default SalaryCut;
