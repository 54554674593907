import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { AuthCountext } from "./AuthCountext";
import Branch from "./subcomponent/Branch";
import Image from "../assets/images/Image";
import FetchUsersBranchList from "../auth/FetchUsersBranchList";

function Branches() {
  const navigate = useNavigate();
  const { setBranch, SERVER_URL, theme, setTitle, userToken, userAdmin } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const branchList = FetchUsersBranchList();

  const z = (b) =>
    data.filter((i) => {
      return i.id === b;
    });

  const x = branchList.map((i) => z(i.branchId));


  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setData(json);
      } else {
        setData([]);
      }
      setIsloading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return data.length > 0 ? (
    <div
      className={`full-width background-${theme} color-${theme}`}
    >
      <div className="flex flex-column justify-center align-center gap-20 p-20 full-width">
        <div className="p-b-50 p-t-80 font-500 font-large">
          <h1>اختر فرع</h1>
          {setTitle("اختر فرع")}
        </div>

        <div
          className={`flex flex-wrap justify-center align-center background-${theme} color-${theme} gap-20`}
        >
          {(userAdmin === '1' || userAdmin === 1)
            ? data.map((item) => (
                <div
                  onClick={() => {
                    setBranch(item.id, item.title);
                    navigate("..", { relative: "route" });
                  }}
                  style={{ zIndex: 2 }}
                  key={item.title}
                  className={`flex flex-column ${
                    isloading ? "align-center" : ""
                  }before-background-color-${theme} before-text-color-${theme} position-relative over-flow justify-center align-center p-20 background-div-${theme} height-250 width-300 border-radius shadow-${theme} cursor-pointer hover-shadow-${theme}`}
                >
                  {isloading ? (
                    <Loader />
                  ) : (
                    <>
                      <div
                        className={`text-decoration flex flex-column justify-space-between align-center color-${theme}`}
                        style={{ zIndex: 2 }}
                      >
                        <Image
                          image={`${SERVER_URL}/${item.logo}`}
                          w={"auto"}
                          h={"80"}
                          name={item.title}
                        />
                        <div>
                          <h3 style={{ textAlign: "center" }}>{item.title}</h3>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))
            : x.map((item) => (
                <div
                  onClick={() => {
                    setBranch(item[0].id, item[0].title);
                    navigate("..", { relative: "route" });
                  }}
                  style={{ zIndex: 2 }}
                  key={item[0].title}
                  className={`flex flex-column ${
                    isloading ? "align-center" : ""
                  }before-background-color-${theme} before-text-color-${theme} position-relative over-flow justify-center align-center p-20 background-div-${theme} height-250 width-300 border-radius shadow-${theme} cursor-pointer hover-shadow-${theme}`}
                >
                  {isloading ? (
                    <Loader />
                  ) : (
                    <>
                      <div
                        className={`text-decoration flex flex-column justify-space-between align-center color-${theme}`}
                        style={{ zIndex: 2 }}
                      >
                        <Image
                          image={`${SERVER_URL}/${item[0].logo}`}
                          w={"auto"}
                          h={"80"}
                          name={item[0].title}
                        />
                        <div>
                          <h3 style={{ textAlign: "center" }}>
                            {item[0].title}
                          </h3>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  ) : (
    <div className="branch full-width">
      <Branch />
    </div>
  );
}

export default Branches;
