import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import { Select } from "antd";
import { useParams } from "react-router-dom";

function FetchEmployee({ setData, defaultValue }) {
  const params = useParams();
  const { SERVER_URL, branchId, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [employee, setEmployee] = useState([]);

  const fetchEmployee = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/fetchemployee/getAllEmployees/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.length > 0) {
        setEmployee(json);
      }

      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEmployee();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };


  return (
    <Select
      name="positionId"
      showSearch
      className={`full-width height-50 border-radius`}
      onChange={(e) =>
        setData((prevData) => {
          return {
            ...prevData,
            employeeId: e,
          };
        })
      }
      optionFilterProp="label"
      placeholder={isloading ? "جارٍ التحميل" : "الموظف"}
      defaultValue={defaultValue ? defaultValue : params.id && Number(params.id)}
      options={employee.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
    />
  );
}

export default FetchEmployee;
