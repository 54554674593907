import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Alert from "../component/subcomponent/Alert";
import { AuthCountext } from "../component/AuthCountext";
import UploadImage from "../component/subcomponent/UploadImage";
import { profileDark, uploadphoto } from "../assets/icons";
import Image from "../assets/images/Image";
import CutPlaceHolder from "../component/subcomponent/CutPlaceHolder";
import Button from "../component/subcomponent/Button";
import {
  Checkbox,
  DatePicker,
  Input,
  Pagination,
  Space,
  Button as Bt,
} from "antd";
import {
  CloseCircleOutlined,
  SafetyOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

dayjs.extend(customParseFormat);

const CreatUserComp = () => {
  const { SERVER_URL, theme, setUsersData, userToken } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);
  // const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [deleletedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [disabledAria, setDisabledAria] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(7);
  const [validPass, setValidPass] = useState(null);

  // const [addButtonLoading, setAddButtonLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: null,
    uname: null,
    upass: null,
    avatar: null,
    admin: false,
    date: null,
  });

  // fetch

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/users/${currentPage}/${dataInPage}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  // end fetch

  // insert data

  const addData = async () => {
    // setAddButtonLoading(true);
    setDisable(true);
    try {
      const response = await fetch(`${SERVER_URL}/users`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة مستخدم بنجاح");
      setAlertName("alert-success");
      alertShow();
      fetchData();
      // setAddButtonLoading(false);
      setDisable(false);
      setUsersData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (
      formData.name !== null &&
      formData.uname !== null &&
      formData.upass !== null &&
      formData.avatar !== null &&
      formData.date !== null
    ) {
      if (formData.upass === validPass) {
        addData();
        setDisable(true);
      } else {
        setIsAlert(true);
        setAlertMsg("كلمة المرور يجب ان تكون متطابقة");
        setAlertName("alert-warning");
        alertShow();
      }
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  // function handleChange(event) {
  //   const { name, value, type, checked } = event.target;
  //   setFormData((prevFormData) => {
  //     return {
  //       ...prevFormData,
  //       [name]: type === "checkbox" ? checked : value,
  //     };
  //   });
  // }

  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    validationFun();
  }

  // end insert

  // delete

  const deleteData = async (id) => {
    setDeleletedId(id);
    // setDeleteButtonLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/users/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit

  const [editFormData, setEditFormData] = useState({
    name: null,
    uname: null,
    upass: null,
    avatar: null,
    admin: false,
    date: null,
  });

  const editData = async (id) => {
    setEditedId(id);
    // setDeleteButtonLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/users/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setDisabledAria(false);
      setEditedId(null);
      setIsRefresh(!isRefresh);
      // setDeleteButtonLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (
      editFormData.name !== null &&
      editFormData.uname !== null &&
      editFormData.upass !== null
    ) {
      if (editFormData.upass === validPass) {
        editData(editFormData.id);
      } else {
        setIsAlert(true);
        setAlertMsg("كلمة المرور يجب ان تكون متطابقة");
        setAlertName("alert-warning");
        alertShow();
      }
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleEditSubmit(event) {
    event.preventDefault();
    editValidationFun();
  }

  // function handleEditChange(event) {
  //   const { name, value, type, checked } = event.target;
  //   setEditFormData((prevEditFormData) => {
  //     return {
  //       ...prevEditFormData,
  //       [name]: type === "checkbox" ? checked : value,
  //     };
  //   });
  // }
  function handleEdit(x, y) {
    setEditedId(x.id);
    setEditFormData((prevEditFormData) => {
      return {
        ...prevEditFormData,
        id: x.id,
        name: x.name,
        uname: x.uname,
        upass: x.upass,
        avatar: x.avatar,
        date: x.date,
      };
    });
    setDisabledAria(y);
  }

  // end edit

  // other

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <Alert msg={alertMsg} alert={alertName} />}
          <form
            className="flex flex-row justify-space-between align-center gap-20 media-flex-column full-width p-b-20"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row justify-space-between align-center gap-20 media-flex-column">
              <Input
                type="text"
                name="name"
                className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                placeholder="الإسم"
                onChange={(e) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      name: e.target.value,
                    };
                  })
                }
              />
              <Input
                type="text"
                name="uname"
                className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                placeholder="اسم المستخدم"
                onChange={(e) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      uname: e.target.value,
                    };
                  })
                }
              />
              <Input
                type="password"
                name="upass"
                className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                placeholder="كلمة السر"
                onChange={(e) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      upass: e.target.value,
                    };
                  })
                }
              />
              <Input
                type="password"
                name="validPass"
                className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                placeholder="تأكيد كلمة السر"
                onChange={(e) => setValidPass(e.target.value)}
              />

              <div className="flex flex-row align-center justify-space-between">
                <Checkbox
                  id="admin"
                  type="checkbox"
                  name="admin"
                  onChange={(e) =>
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        admin: e.target.checked ? true : false,
                      };
                    })
                  }
                >
                  أدمن
                </Checkbox>
              </div>
              <UploadImage
                key={1}
                // the name in the formData
                name="avatar"
                // the formData.
                data={formData}
                // setFormData.
                setData={setFormData}
                // the placeholder you want to show in.
                placeholder={
                  <Bt style={{ height: "50px" }} icon={<UploadOutlined />}>
                    حمل صورة شخصية
                  </Bt>
                }
                // the image link witch stored in name attribute.
                image={formData.avatar}
                // icon
                icon={theme === "dark" ? profileDark : uploadphoto}
                listType={false}
              />

              <DatePicker
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                }}
                size={"large"}
                placeholder="التاريخ"
                onChange={(date, dateString) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      date: dateString,
                    };
                  })
                }
              />
            </div>
            <Button
              button={"add"}
              disable={disable}
              title={"إضافة"}
              type="primary"
              onClickMethod={handleSubmit}
              danger={false}
            />
          </form>
        </div>
        <div
          className={` over-flow-scroll over-flow-scroll-${theme}`}
          style={{ minHeight: "500px", overflow: "scroll", width: "100%" }}
        >
          <ul
            className={`border-bottom-${theme}-3 font-bold`}
            style={{ minWidth: "900px", width: "100%" }}
          >
            <li className="flex flex-row align-center justify-start width-5-100">
              الرقم
            </li>
            <li className="flex flex-row align-center justify-start width-20-100">
              الاسم
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              اسم المستخدم
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              كلمة المرور
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              رئيسي
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              تاريخ الانشاء
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              الصلاحيات
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              تعديل
            </li>
            <li className="flex flex-row align-center justify-start width-10">
              حذف
            </li>
          </ul>
          {isloading ? (
            <Place />
          ) : data.length === 0 ? (
            <h1>لا توجد بيانات</h1>
          ) : (
            data?.map((item) => (
              <>
                {disabledAria && editedId === item.id ? (
                  <form onSubmit={handleEditSubmit}>
                    <ul
                      className={`border-bottom-${theme}-1`}
                      key={item.id}
                      style={{ minWidth: "900px", width: "100%" }}
                    >
                      <li className="flex flex-row align-center justify-start width-5-100">
                        {item.id}
                      </li>
                      <li className="flex flex-column align-start gap-10 justify-start width-20-100">
                        <Input
                          type="text"
                          name="name"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="الإسم"
                          defaultValue={item.name}
                          onChange={(e) =>
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                name: e.target.value,
                              };
                            })
                          }
                        />
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <Input
                          type="text"
                          name="uname"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="اسم المستخدم"
                          defaultValue={item.uname}
                          onChange={(e) =>
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                uname: e.target.value,
                              };
                            })
                          }
                        />
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <Input
                          type="password"
                          name="upass"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="كلمة السر"
                          defaultValue={item.upass}
                          onChange={(e) =>
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                upass: e.target.value,
                              };
                            })
                          }
                        />
                        <Input
                          type="password"
                          name="validPass"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="تأكيد كلمة السر"
                          onChange={(e) => setValidPass(e.target.value)}
                        />
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <Checkbox
                          id="admin"
                          type="checkbox"
                          name="admin"
                          defaultChecked={item.admin}
                          onChange={(e) =>
                            setFormData((prevFormData) => {
                              return {
                                ...prevFormData,
                                admin: e.target.checked ? true : false,
                              };
                            })
                          }
                        >
                          أدمن
                        </Checkbox>
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <UploadImage
                          key={1}
                          // the name in the formData
                          name="avatar"
                          // the formData.
                          data={editFormData}
                          // setFormData.
                          setData={setEditFormData}
                          // the placeholder you want to show in.
                          placeholder={
                            <Bt
                              style={{ height: "50px" }}
                              icon={<UploadOutlined />}
                            >
                              حمل صورة شخصية
                            </Bt>
                          }
                          // the image link witch stored in name attribute.
                          image={editFormData.avatar}
                          // icon
                          icon={theme === "dark" ? profileDark : uploadphoto}
                          listType={false}
                        />
                      </li>
                      <li className="flex flex-row align-center justify-start width-10"></li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <Button
                          button={"save"}
                          buttonId={editedId}
                          id={item.id}
                          disable={disable}
                          title={"حفظ"}
                          type="default"
                          onClickMethod={handleEditSubmit}
                          danger={false}
                          loading={isloading}
                        />
                      </li>
                      <li className="flex flex-row align-center justify-start width-10">
                        <Space>
                          <CloseCircleOutlined
                            className="font-24px transition font-hover "
                            onClick={(e) => setDisabledAria(false)}
                          />
                        </Space>
                      </li>
                    </ul>
                  </form>
                ) : (
                  <ul
                    className={`border-bottom-${theme}-1`}
                    key={item.id}
                    style={{ minWidth: "900px", width: "100%" }}
                  >
                    <li className="flex flex-row align-center justify-start width-5-100">
                      {item.id}
                    </li>
                    <li className="flex flex-row align-center justify-start width-20-100">
                      <li className="flex flex-row align-center justify-start width-300 gap-10">
                        <div className="flex flex-row justify-start align-center round over-flow width-40 height-40">
                          <Image
                            image={`${SERVER_URL}/${item.avatar}`}
                            w={40}
                            h={40}
                            name={item?.avatar}
                          />
                        </div>
                        <div>{item.name}</div>
                      </li>
                    </li>
                    <li className="flex flex-row align-center justify-start width-10">
                      {item.uname}
                    </li>
                    <li className="flex flex-row align-center justify-start width-10">
                      {item.upass}
                    </li>
                    <li className="flex flex-row align-center justify-start width-10">
                      {item.admin}
                    </li>
                    <li className="flex flex-row align-center justify-start width-10">
                      {item.date}
                    </li>
                    <li className="flex flex-row align-center justify-start width-10">
                      <Link
                        to={`usersauthbranch/${item.id}`}
                        className={`color-${theme} text-center flex gap-10 justify-space-between align-center text-decoration p-5 border-radius`}
                      >
                        <SafetyOutlined />
                        <span>الصلاحيات</span>
                      </Link>
                    </li>

                    <li className="flex flex-row align-center justify-start width-10 position-relative">
                      <Button
                        type="primary"
                        button="edit"
                        onClickMethod={() => handleEdit(item, true)}
                        buttonId={editedId}
                        id={item.id}
                        disable={disable}
                        title={"تعديل"}
                        danger={false}
                        loading={isloading}
                      />
                    </li>
                    <li className="flex flex-row align-center justify-start width-10 position-relative">
                      <Button
                        type="primary"
                        button="delete"
                        onClickMethod={() => deleteData(item.id, true)}
                        buttonId={deleletedId}
                        id={item.id}
                        disable={disable}
                        loading={isloading}
                        title={"حذف"}
                        danger={true}
                      />
                    </li>
                  </ul>
                )}
              </>
            ))
          )}
        </div>
      </div>

      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default CreatUserComp;
