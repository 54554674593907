import React, { useContext } from "react";
import { AuthCountext } from "./AuthCountext";

function Footer() {
  const { branchName } = useContext(AuthCountext);
  const year = new Date().getUTCFullYear();
  return (
    <div
      style={{
        minHeight: "20px",
        width: "100%",
        textAlign: "center",
        marginTop: "auto",
        paddingTop: "10px",
        direction: "ltr",
      }}
    >
      ©{" "}
      <span>
        {year} {branchName}
      </span>
    </div>
  );
}

export default Footer;
