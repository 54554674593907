import { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../component/AuthCountext";

function FetchUsersBranchList() {
  const { SERVER_URL, userToken } = useContext(AuthCountext);
  const [data, setData] = useState([]);

  const fetchUsersBranchList = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/usersauthbranch/${userToken}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setData(json);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUsersBranchList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}

export default FetchUsersBranchList;
