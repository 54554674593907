import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  DoughnutController,
  ArcElement,
  LinearScale,
  Tooltip,
  Legend,
  Colors,
  defaults,
} from "chart.js";

ChartJS.register(
  DoughnutController,
  ArcElement,
  LinearScale,
  Tooltip,
  Legend,
  Colors
);


// defaults.font.size = 12;
defaults.font.family = "'Noto Kufi Arabic', sans-serif";
defaults.borderColor = "rgba(255,255,255,0.1)";
// defaults.color = '#e3e3e3';

function DoughnutChart({
  x,
  y,
  title,
  titleDisplay,
  colors,
  legendOn,
  toltipOn,
  theme
}) {
  const options = {
    // cutout: 35,
    plugins: {
      maintainAspectRatio: false,
      responsive: true,
      colors: {
        forceOverride: colors ? false : true,
      },
      title: {
        display: titleDisplay,
        text: title,
        position: "bottom",
        color: theme === 'light' ? "#e3e3e3" : "#233142",
      },
      legend: {
        display: legendOn,
        position: "right",
        align: "center",
        maxWidth: 200,
        fullSize: false,
        rtl: true,
        labels: {
          font: {
            size: 9,
          },
          color: theme === 'light' ? "#e3e3e3" : "#233142",
          padding: 10,
        },
      },
      tooltip: {
        enabled: toltipOn,
      },
    },
  };

  const data = {
    labels: x,
    datasets: [
      {
        label: title,
        data: y,
        hoverOffset: 5,
        weight: 1,
        backgroundColor: colors,
        borderWidth: 0,
        spacing: 1,
        rotation: 50,
        barThickness: "10",
      },
    ],
  };

  return <Doughnut options={options} data={data} style={{ width: "100%" }} />;
}

export default DoughnutChart;
