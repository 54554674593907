import ParentComp from "../ParentComp";
import IncreaseComp from "./IncreaseComp";

function Increase() {
  return (
    <>
      <ParentComp title={"العلاوة"} prevPage={"الزيادات"} linkto={"/addition/"}>
        <IncreaseComp />
      </ParentComp>
    </>
  );
}

export default Increase;
