import ParentComp from "../ParentComp";
import AbsenceComp from "./AbsenceComp";

function Absence() {
  return (
    <>
      <ParentComp title={"الغياب"} prevPage={"الخصومات"} linkto={"/salarycut/"}>
        <AbsenceComp />
      </ParentComp>
    </>
  );
}

export default Absence;
