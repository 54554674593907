import React, { useState, useEffect, useContext } from "react";
import { absenceImage, salaryCut } from "../assets/icons";
import { AuthCountext } from "./AuthCountext";
import DashboardDiv from "./subcomponent/DashboardDiv";

const SalaryCutComp = () => {
  const { SERVER_URL, branchId, theme, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [salarycut, setSalarycut] = useState(0);
  const [absence, setAbsence] = useState(0);

  const list = [
    {
      name: "salarycut",
      title: "خصم",
      result: salarycut,
      img: salaryCut,
      link: "/cut",
    },
    {
      name: "absence",
      title: "غياب",
      result: absence,
      img: absenceImage,
      link: "/absence",
    },
  ];

  const fetchSalaryCutCount = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/cut/count/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setSalarycut(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAbsenceCount = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/absence/count/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setAbsence(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSalaryCutCount();
    fetchAbsenceCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={`flex flex-column background-${theme} color-${theme} gap-10 full-width`}
    >
      <DashboardDiv list={list} isloading={isloading} />
    </div>
  );
};

export default SalaryCutComp;
