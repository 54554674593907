import DashboardComp from "./DashboardComp";
import ParentComp from "./ParentComp";

function Dashboard() {
  return (
    <>
      <ParentComp title={"لوحة البيانات"}>
        <DashboardComp />
      </ParentComp>
    </>
  );
}

export default Dashboard;
