import { Progress, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "./AuthCountext";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { SnippetsOutlined } from "@ant-design/icons";

function Documents() {
  const { theme, branchId, SERVER_URL, userToken } = useContext(AuthCountext);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const fetchMainRecordsData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/maindocsrecords/fetchall/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();

      json.length > 0 && setData(json);
      setIsloading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMainRecordsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function difDates(start, end) {
    let date1 = new Date(start);
    let date2 = new Date(end);

    // Calculating the time difference
    // of two dates
    let Difference_In_Time = date2.getTime() - date1.getTime();

    // Calculating the no. of days between
    // two dates
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    return Difference_In_Days;
  }

  const percent = (start, end) => {
    const x = Math.round(
      (difDates(new Date(), end) / difDates(start, end)) * 100
    );
    return 100 - x;
  };

  return (
    <div
      className="flex flex-column media-flex-column justify-start full-width position-relative full-height over-flow"
      
    >
      <Tooltip title="صفحة المستندات الثبوتية" style={{ fontSize: "small" }}>
        <Link
          to="/settings/maindocs/maindocsrecords"
          className={`color-${theme} text-center flex gap-10 justify-space-between align-center text-decoration p-5 border-radius`}
          style={{ alignSelf: "baseline", fontSize: "16px" }}
        >
          <SnippetsOutlined />
          <h1 style={{ alignSelf: "baseline", fontSize: "14px" }}>
            المستندات الثبوتية
          </h1>
        </Link>
      </Tooltip>
      <div
        className={`flex flex-column color-${theme} full-width over-flow-scroll full-width over-flow`}
      >
        <div
          className={`flex flex-column justify-start align-center color-${theme}`}
          style={{ fontSize: "11px", height: "200px", minWidth: "400px" }}
        >
          <div
            className={`flex flex-row justify-start align-center color-${theme} full-width gap-10`}
            style={{ marginBlock: "5px" }}
          >
            <span
              style={{
                width: "110px",
                textAlign: "right",
                fontWeight: "bold",
                flexGrow: "1",
              }}
            >
              اسم المستند
            </span>
            <span
              style={{ width: "70px", textAlign: "right", fontWeight: "bold" }}
            >
              تاريخ الاصدار
            </span>
            <span
              style={{ width: "150px", textAlign: "right", fontWeight: "bold" }}
            >
              المسار الزمني
            </span>
            <span
              style={{ width: "70px", textAlign: "right", fontWeight: "bold" }}
            >
              تاريخ الانتهاء
            </span>
          </div>
          {isloading ? (
            <Loader size="medium" />
          ) : (
            data?.map((item) => (
              <div
                className={`flex flex-row justify-start align-center color-${theme} full-width gap-10 hover-background-div-${theme}`}
                key={item.id}
              >
                <span
                  style={{ width: "110px", textAlign: "right", flexGrow: "1" }}
                >
                  {item.docsTitle}
                </span>
                <span style={{ width: "70px", textAlign: "right" }}>
                  {item.issueDate}
                </span>
                <Progress
                  percentPosition={{
                    align: "center",
                    type: "inner",
                  }}
                  percent={percent(item.issueDate, item.expireDate)}
                  style={{
                    marginBottom: "0",
                    width: "150px",
                    fontSize: "12px",
                  }}
                  strokeColor={
                    percent(item.issueDate, item.expireDate) >= "100"
                      ? "red"
                      : percent(item.issueDate, item.expireDate) >= "90"
                      ? "#FFC000"
                      : ""
                  }
                  status={
                    percent(item.issueDate, item.expireDate) >= "100" &&
                    "exception"
                  }
                />
                <span style={{ width: "70px", textAlign: "right" }}>
                  {item.expireDate}
                </span>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Documents;
