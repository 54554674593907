import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { tbukregular, tbukrelight, tbukBold } from "../../assets/fonts";

Font.register({
  family: "tabuk",
  fonts: [
    {
      src: tbukregular,
      fontWeight: "normal",
    },
    {
      src: tbukBold,
      fontWeight: "bold",
    },
    {
      src: tbukrelight,
      fontWeight: "light",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "tabuk",
    fontSize: "9px",
    height: "100%",
    padding: "20px",
  },
  section: {
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    display: "flex",
    flexDirection: "row-reverse",
  },
  header: {
    height: "100px",
    borderBottom: "1px solid #ccc",
  },
  title: {
    height: "50px",
    fontWeight: "bold",
  },
  info: {
    height: "120px",
  },
  body: {
    height: "300px",
    borderBottom: "1px solid #ccc",
  },
  footer: {
    height: "50px",
  },
  row: {
    width: "30%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "none",
  },
  label: {
    fontWeight: "light",
  },
  data: {
    fontWeight: "bold",
  },
});

// Create Document Component
export const Pdf = ({ companyInfos, img64, idData, title, id }) => {
  console.log(idData[0]?.id);
  return idData?.map((item) => (
    <Document
      title={`${title} رقم ${item.id} - ${item.name}`}
      language="ar"
      pageLayout="twoPageLeft"
    >
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={{width: '100%', height: '100%', border: '1px solid #ccc', padding: '10px'}}>
          
        <View style={[styles.section, styles.header]}>
          <View style={{ width: "30%", textAlign: "center", justifyContent: 'flex-start', height:'auto' }}>
            <Text style={styles.data}>{companyInfos[0]?.title}</Text>
            <Text>{companyInfos[0]?.address}</Text>
            <Text>{companyInfos[0]?.crnumber} : سجل تجاري </Text>
          </View>

          <Image
            src={img64.img}
            style={{
              width: "30%",
              height: "50px",
              objectFit: "contain",
              alignContent: "flex-start",
            }}
          />

          <View style={{ width: "30%", textAlign: "center", justifyContent: 'flex-start', height:'auto' }}>
            <Text style={styles.data}>{companyInfos[0]?.entitle}</Text>
            <Text>{companyInfos[0]?.enaddress}</Text>
            <Text>C.R.: {companyInfos[0]?.crnumber}</Text>
          </View>
        </View>
        <View
          style={[
            styles.section,
            styles.title,
            { height: "70px", alignItems: "flex-start", paddingTop: "10px" },
          ]}
        >
          <Text
            style={{ textAlign: "right", fontWeight: "light", width: "30%" }}
          >
            رقم المستند: {item.id}
          </Text>
          <Text
            style={{
              fontSize: "16px",
              flexGrow: "1",
              textAlign: "center",
              width: "40%",
            }}
          >
            {title}
          </Text>
          <Text
            style={{ textAlign: "left", fontWeight: "light", width: "30%" }}
          >
            {item.thedate}
          </Text>
        </View>
        <Text
          style={{
            fontWeight: "bold",
            height: "25px",
            textAlign: "right",
            width: "100%",
          }}
        >
          Employee Data | بيانات الموظف
        </Text>
        <View
          style={[
            styles.section,
            styles.info,
            {
              flexDirection: "column",
              border: "1px solid #ccc",
              padding: "10px",
            },
          ]}
        >
          <View style={styles.section}>
            <Text style={[styles.row, styles.label, { textAlign: "right" }]}>
              اسم الموظف
            </Text>
            <Text style={[styles.row, styles.data, { textAlign: "center" }]}>
              {item.name}
            </Text>
            <Text style={[styles.row, styles.label, { textAlign: "left" }]}>
              Employee Name
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={[styles.row, styles.label, { textAlign: "right" }]}>
              الرقم الوظيفي
            </Text>
            <Text style={[styles.row, styles.data, { textAlign: "center" }]}>
              {item.employeeId}
            </Text>
            <Text style={[styles.row, styles.label, { textAlign: "left" }]}>
              Employee ID
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={[styles.row, styles.label, { textAlign: "right" }]}>
              المسمى الوظيفي
            </Text>
            <Text style={[styles.row, styles.data, { textAlign: "center" }]}>
              {item.title}
            </Text>
            <Text style={[styles.row, styles.label]}>Job Title</Text>
          </View>
          <View style={styles.section}>
            <Text style={[styles.row, styles.label, { textAlign: "right" }]}>
              الهوية
            </Text>
            <Text style={[styles.row, styles.data, { textAlign: "center" }]}>
              {item.idNumber}
            </Text>
            <Text style={[styles.row, styles.label]}>ID Number</Text>
          </View>
          <View style={styles.section}>
            <Text style={[styles.row, styles.label, { textAlign: "right" }]}>
              الجوال
            </Text>
            <Text style={[styles.row, styles.data, { textAlign: "center" }]}>
              {item.phone}
            </Text>
            <Text style={[styles.row, styles.label]}>Phone Number</Text>
          </View>
        </View>
        <View
          style={[
            styles.section,
            styles.body,
            {
              flexGrow: "1",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingTop: "50px",
            },
          ]}
        >
          <Text
            style={{
              paddingBottom: "20px",
              fontWeight: "bold",
              textAlign: "right",
              width: "100%",
            }}
          >
            Financial statements | البيانات المالية
          </Text>
          <View
            style={[
              {
                flexDirection: "column",
                border: "1px solid #ccc",
                padding: "10px",
                width: "100%",
                height: item.installmentNumber ? "140px" : "80px",
                justifyContent: "space-between",
              },
            ]}
          >
            {item.amount && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  المبلغ
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.amount}
                </Text>
                <Text style={[styles.row, styles.label]}>Amount</Text>
              </View>
            )}
            {id === "vacation" && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  بداية الإجازة
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.start}
                </Text>
                <Text style={[styles.row, styles.label]}>
                  Vacation start at
                </Text>
              </View>
            )}
            {item.end && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  نهاية الإجازة
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.end}
                </Text>
                <Text style={[styles.row, styles.label]}>Vacation end at</Text>
              </View>
            )}
            {item.numberOfInstallments && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  عدد الأقساط
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.numberOfInstallments}
                </Text>
                <Text style={[styles.row, styles.label]}>Installments.</Text>
              </View>
            )}
            {item.cause && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  البيان
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.cause}
                </Text>
                <Text style={[styles.row, styles.label]}>The Statement.</Text>
              </View>
            )}
            {item.advanceAmount && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  مبلغ السلفة
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.advanceAmount}
                </Text>
                <Text style={[styles.row, styles.label]}>Advance Amount.</Text>
              </View>
            )}
            {item.advanceDate && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  تاريخ السلفة
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.advanceDate}
                </Text>
                <Text style={[styles.row, styles.label]}>Advance Date.</Text>
              </View>
            )}
            {item.installmentNumber && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  رقم القسط
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.installmentNumber}
                </Text>
                <Text style={[styles.row, styles.label]}>
                  Installments Number.
                </Text>
              </View>
            )}
            {id === "overtime" && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  عدد الساعات
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.hours}
                </Text>
                <Text style={[styles.row, styles.label]}>Hours.</Text>
              </View>
            )}
            {(id === "increase") && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  تاريخ الإعتماد
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.thedate}
                </Text>
                <Text style={[styles.row, styles.label]}>
                  Date of Increase.
                </Text>
              </View>
            )}
            {(id === "overtime") && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  التاريخ
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.thedate}
                </Text>
                <Text style={[styles.row, styles.label]}>
                  Date.
                </Text>
              </View>
            )}
            {id === "installments" | id === 'advance' && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  بداية الخصم
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.start}
                </Text>
                <Text style={[styles.row, styles.label]}>Start from</Text>
              </View>
            )}
            {item.installmentSum && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  مجموع الأقساط
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.installmentSum}
                </Text>
                <Text style={[styles.row, styles.label]}>
                  Installments Sum.
                </Text>
              </View>
            )}
            {item.installmentSum && (
              <View style={styles.section}>
                <Text
                  style={[styles.row, styles.label, { textAlign: "right" }]}
                >
                  الباقي
                </Text>
                <Text
                  style={[styles.row, styles.data, { textAlign: "center" }]}
                >
                  {item.advanceAmount - item.installmentSum}
                </Text>
                <Text style={[styles.row, styles.label]}>Installments.</Text>
              </View>
            )}
          </View>
          <View
            style={[
              {
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                height: "150px",
                gap: "20px",
              },
            ]}
          >
            <View style={styles.section}>
              <View
                style={[
                  styles.section,
                  {
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  },
                ]}
              >
                <Text style={{ textAlign: "right" }}>Name | الإسم</Text>
                <Text
                  style={[
                    styles.data,
                    { textAlign: "right", paddingTop: "10px" },
                  ]}
                >
                  {item.name}
                </Text>
              </View>
              <View
                style={[
                  styles.section,
                  {
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  },
                ]}
              >
                <Text style={{ textAlign: "right" }}>
                  Personnel affairs | شؤون الموظفين
                </Text>
                <Text
                  style={[
                    styles.label,
                    { textAlign: "right", paddingTop: "10px" },
                  ]}
                >
                  ....................
                </Text>
              </View>
            </View>
            <View style={styles.section}>
              <View
                style={[
                  styles.section,
                  {
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  },
                ]}
              >
                <Text style={{ textAlign: "right" }}>Signature | التوقيع</Text>
                <Text style={{ textAlign: "right", paddingTop: "10px" }}>
                  ....................
                </Text>
              </View>
              <View
                style={[
                  styles.section,
                  {
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  },
                ]}
              >
                <Text style={{ textAlign: "right" }}>Signature | التوقيع</Text>
                <Text style={{ textAlign: "right", paddingTop: "10px" }}>
                  ....................
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.section, styles.footer, {fontSize: '8px', opacity: '0.8'}]}>
          <View style={{flexGrow: '1', alignItems: 'flex-end'}}>
          <Text>{companyInfos[0]?.address}</Text>
          <Text>{companyInfos[0]?.email} | {companyInfos[0]?.phone}</Text>
          </View>
          <View style={{textAlign: 'right', flexGrow: '1'}}>
          <Text>{companyInfos[0]?.enaddress}</Text>
          <Text>{companyInfos[0]?.email} | {companyInfos[0]?.phone}</Text>
          </View>
        </View>
      
        </View>
      </Page>
    </Document>
  ));
};
