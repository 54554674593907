import React, { useEffect, useState } from "react";
import { Pdf } from "../subcomponent/Pdf";

function OvertimePdf({ employeeId, companyInfos, img64, url, userToken, branchId }) {
  
  const [idData, setIdData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${url}/overtimepdf/${branchId}/${employeeId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      console.log(json)
      if (json.msg !== "No data") {
        if (json.length > 0) {
          setIdData(json);
        }
      } else {
        setIdData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Pdf
      companyInfos={companyInfos}
      img64={img64}
      idData={idData}
      title="إضافي"
      id="overtime"
    />
  );
}

export default OvertimePdf;
