function Image({ image, w, h, name, contain }) {
  return (
    <img
      className={`${contain ? `img-contain` : `cover`} border-radius`}
      src={image}
      alt={name}
      width={w}
      height={h}
      style={{ transition: 'all ease-in-out 0.5s'}}
    />
  );
}

export default Image;
