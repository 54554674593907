import { useContext, useEffect, useState } from 'react'
import { AuthCountext } from "./AuthCountext";

export const Totals = () => {
    const { SERVER_URL, userToken, branchId } = useContext(AuthCountext);
    const [totalBranches, setTotalBranches] = useState(0);
    const [maindocs, setMaindocs] = useState(0)
    const [paymethod, setPaymethod] = useState(0);
    const [jobs, setJobs] = useState(0);
    const [users, setUsers] = useState(0);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [avg, setAvg] = useState(0);

    const fetchBranchCount = async () => {
        try {
          const response = await fetch(`${SERVER_URL}/branch/count`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          });
          const json = await response.json();
          if (!json.msg) {
            setTotalBranches(json);
          }
        } catch (error) {
          console.error(error);
        }
      };

      const fetchMainDocsCount = async () => {
        try {
          const response = await fetch(`${SERVER_URL}/maindocs/count`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          });
          const json = await response.json();
          if (!json.msg) {
            setMaindocs(json);
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      const fetchPostirionCount = async () => {
        try {
          const response = await fetch(`${SERVER_URL}/positiontitle/count`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          });
          const json = await response.json();
          if (!json.msg) {
            setJobs(json);
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      const fetchPayMethodCount = async () => {
        try {
          const response = await fetch(`${SERVER_URL}/paymethod/count`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          });
          const json = await response.json();
          if (!json.msg) {
            setPaymethod(json);
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      const fetchUsersCount = async () => {
        try {
          const response = await fetch(`${SERVER_URL}/users/count`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          });
          const json = await response.json();
          if (!json.msg) {
            setUsers(json);
          }
        } catch (error) {
          console.error(error);
        }
      };

      const fetchCount = async () => {
        try {
          const response = await fetch(
            `${SERVER_URL}/fetchemployee/count/${branchId}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-api-key": userToken,
              },
            }
          );
          const json = await response.json();
          if (!json.msg) {
            setTotalEmployees(json);
          }
        } catch (error) {
          console.error(error);
        }
      };
    
      const fetchAvg = async () => {
        try {
          const response = await fetch(
            `${SERVER_URL}/fetchemployee/avg/${branchId}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-api-key": userToken,
              },
            }
          );
          const json = await response.json();
          if (!json.msg) {
            setAvg(json);
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      useEffect(() => {
        fetchBranchCount();
        fetchMainDocsCount()
        fetchPostirionCount();
        fetchPayMethodCount();
        fetchUsersCount();
        fetchCount()
        fetchAvg()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  

  return {
    totalBranches,
    maindocs,
    paymethod,
    jobs,
    users,
    avg,
    totalEmployees
  }
}
