import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { DatePicker, Input, Pagination, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import Alert from "./Alert";
import { AuthCountext } from "../AuthCountext";
import CutPlaceHolder from "./CutPlaceHolder";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "./Button";
import FetchEmployee from "./FetchEmployee";

const CutComp = () => {
  const { SERVER_URL, branchId, theme, userToken, branch } =
    useContext(AuthCountext);
  const navigate = useNavigate()
  const params = useParams();
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);
  // const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [deleletedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [disabledAria, setDisabledAria] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(7);

  const [formData, setFormData] = useState({
    employeeId: params.id || null,
    amount: null,
    cause: null,
    date: null,
  });

  // fetch

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${SERVER_URL}/cut/${currentPage}/${dataInPage}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, SERVER_URL, branchId, currentPage, dataInPage]);

  // end fetch

  // insert data

  const addCut = async () => {
    setDisable(true);
    try {
      const response = await fetch(`${SERVER_URL}/cut`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      fetchData();
      setDisable(false);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (
      formData.id !== null &&
      formData.amount !== null &&
      formData.cause !== null &&
      formData.date !== null
    ) {
      addCut();
      setDisable(true);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    console.log(formData);
    validationFun();
  }

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  // end insert

  // delete
  const deleteData = async (id) => {
    setDeleletedId(id);
    // setDeleteButtonLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/cut/${id}/${branch}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    id: null,
    employeeId: null,
    amount: null,
    cause: null,
    date: null,
  });

  const editData = async (id) => {
    setEditedId(id);
    try {
      const response = await fetch(`${SERVER_URL}/cut/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setDisabledAria(false);
      setEditedId(null);
      setIsRefresh(!isRefresh);
      // setDeleteButtonLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (
      editFormData.id !== null &&
      editFormData.employeeId !== null &&
      editFormData.amount !== null &&
      editFormData.cause !== null &&
      editFormData.date !== null
    ) {
      editData(editFormData.id);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };
  function handleEditSubmit(event) {
    event.preventDefault();
    editValidationFun();
    // setDisabledAria(false);
  }
  function handleEditChange(event) {
    const { name, value, type, checked } = event.target;
    setEditFormData((prevEditFormData) => {
      return {
        ...prevEditFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  function handleEdit(x, y) {
    console.log(x.id);
    setEditedId(x.id);
    setEditFormData((prevEditFormData) => {
      return {
        ...prevEditFormData,
        id: x.id,
        employeeId: x.employeeId,
        amount: x.amount,
        cause: x.cause,
        date: x.date,
      };
    });
    setDisabledAria(y);
  }

  // end edit

  // other

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <Alert msg={alertMsg} alert={alertName} />}
          <form
            className="flex flex-row justify-space-between align-center gap-20 media-flex-column full-width p-b-20"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row justify-space-between align-center gap-20 media-flex-column">
              <FetchEmployee
                setData={setFormData}
                handleChange={handleChange}
                params={params}
              />

              <Input
                type="text"
                name="amount"
                className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                placeholder="المبلغ"
                onChange={(e) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      amount: e.target.value,
                    };
                  })
                }
              />
              <Input
                type="text"
                name="cause"
                className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                placeholder="السبب"
                onChange={(e) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      cause: e.target.value,
                    };
                  })
                }
              />

              <DatePicker
                style={{ width: "100%", height: "50px", borderRadius: "10px" }}
                size={"large"}
                placeholder="التاريخ"
                onChange={(date, dateString) =>
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      date: dateString,
                    };
                  })
                }
              />
            </div>

            <Button
              button={"add"}
              disable={disable}
              title={"إضافة"}
              type="primary"
              onClickMethod={handleSubmit}
              danger={false}
            />
          </form>
        </div>
        <div
          className={`over-flow-scroll over-flow-scroll-${theme}`}
          style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
        >
          <ul
            className={`border-bottom-${theme}-3 font-bold`}
            style={{ minWidth: "900px", width: "100%" }}
          >
            <li
              className="flex flex-row align-center justify-start width-5-100"
              style={{ minWidth: "50px" }}
            >
              الرقم
            </li>
            <li
              className="flex flex-row align-center justify-start width-20-100"
              style={{ minWidth: "150px" }}
            >
              الاسم
            </li>
            <li
              className="flex flex-row align-center justify-start width-10"
              style={{ minWidth: "100px" }}
            >
              المبلغ
            </li>
            <li
              className="flex flex-row align-center justify-start width-10"
              style={{ minWidth: "150px" }}
            >
              البيان
            </li>
            <li
              className="flex flex-row align-center justify-start width-10"
              style={{ minWidth: "150px" }}
            >
              التاريخ
            </li>

            <li
              className="flex flex-row align-center justify-start width-10"
              style={{ minWidth: "150px" }}
            >
              تعديل
            </li>
            <li
              className="flex flex-row align-center justify-start width-10"
              style={{ minWidth: "150px" }}
            >
              حذف
            </li>
          </ul>
          {isloading ? (
            <Place />
          ) : data.length === 0 ? (
            <h1>لا توجد بيانات</h1>
          ) : (
            data?.map((item) => (
              <>
                {disabledAria && editedId === item.id ? (
                  <form onSubmit={handleEditSubmit}>
                    <ul
                      className={`border-bottom-${theme}-1`}
                      key={item.id}
                      style={{ minWidth: "900px", width: "100%" }}
                    >
                      <li className="flex flex-row align-center justify-start width-5-100">
                        {item.id}
                      </li>
                      <li
                        className="flex flex-row align-center justify-start width-20-100"
                        style={{ minWidth: "50px" }}
                      >
                        <FetchEmployee
                          setData={setEditFormData}
                          handleChange={handleEditChange}
                          defaultValue={item.employeeId}
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start width-10"
                        style={{ minWidth: "150px" }}
                      >
                        <Input
                          type="text"
                          name="amount"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="المبلغ"
                          defaultValue={item.amount}
                          onChange={(e) =>
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                amount: e.target.value,
                              };
                            })
                          }
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start width-10"
                        style={{ minWidth: "100px" }}
                      >
                        <Input
                          type="text"
                          name="cause"
                          className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                          placeholder="السبب"
                          defaultValue={item.cause}
                          onChange={(e) =>
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                cause: e.target.value,
                              };
                            })
                          }
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start width-10"
                        style={{ minWidth: "150px" }}
                      >
                        <DatePicker
                          defaultValue={dayjs(item.date, "YYYY-MM-DD")}
                          style={{
                            width: "100%",
                            height: "50px",
                            borderRadius: "10px",
                          }}
                          size={"large"}
                          placeholder="التاريخ"
                          onChange={(date, dateString) =>
                            setEditFormData((prevEditFormData) => {
                              return {
                                ...prevEditFormData,
                                date: dateString,
                              };
                            })
                          }
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start width-10"
                        style={{ minWidth: "150px" }}
                      >
                        <Button
                          button={"save"}
                          buttonId={editedId}
                          id={item.id}
                          disable={disable}
                          title={"حفظ"}
                          type="default"
                          onClickMethod={handleEditSubmit}
                          danger={false}
                          loading={isloading}
                        />
                      </li>
                      <li
                        className="flex flex-row align-center justify-start width-10"
                        style={{ minWidth: "150px" }}
                      >
                        <Space>
                          <CloseCircleOutlined
                            className="font-24px transition font-hover "
                            onClick={(e) => setDisabledAria(false)}
                          />
                        </Space>
                      </li>
                    </ul>
                  </form>
                ) : (
                  <ul
                    className={`border-bottom-${theme}-1`}
                    key={item.id}
                    style={{ minWidth: "900px", width: "100%" }}
                  >
                    <li
                      className="flex flex-row align-center justify-start width-5-100"
                      style={{ minWidth: "50px" }}
                    >
                      {item.id}
                    </li>
                    <li
                      className="flex flex-row align-center text-align-start justify-start width-20-100 gap-10"
                      style={{ minWidth: "150px" }}
                    >
                      <Link
                        className={`color-${theme} text-decoration hover-background-div-${theme}`}
                        to={`/employee/${item.employeeId}`}
                      >
                        <div>{item.name}</div>
                      </Link>
                    </li>
                    <li
                      className="flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "100px" }}
                    >
                      {item.amount}
                    </li>
                    <li
                      className="flex flex-row align-center text-align-start justify-start width-10"
                      style={{ minWidth: "150px" }}
                    >
                      {item.cause}
                    </li>
                    <li
                      className="flex flex-row align-center justify-start width-10"
                      style={{ minWidth: "150px" }}
                    >
                      {item.date}
                    </li>

                    <li
                      className="flex flex-row align-center justify-space-between width-10 position-relative"
                      style={{ minWidth: "150px" }}
                    >
                     <Button
                        type="primary"
                        button="pdf"
                        onClickMethod={() =>
                          navigate(`/pdf/cut/${item.id}`)
                        }
                      />
                      <Button
                        type="primary"
                        button="edit"
                        onClickMethod={() => handleEdit(item, true)}
                        buttonId={editedId}
                        id={item.id}
                        disable={disable}
                        title={"تعديل"}
                        danger={false}
                        loading={isloading}
                      />
                    </li>
                    <li
                      className="flex flex-row align-center justify-start width-10 position-relative"
                    >
                      <Button
                        type="primary"
                        button="delete"
                        onClickMethod={() => deleteData(item.id, true)}
                        buttonId={deleletedId}
                        id={item.id}
                        disable={disable}
                        loading={isloading}
                        title={"حذف"}
                        danger={true}
                      />
                    </li>
                  </ul>
                )}
              </>
            ))
          )}
        </div>
      </div>
      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default CutComp;
