import ParentComp from "../ParentComp";
import AdvaceComp from "./AdvaceComp";

function Advance() {
  return (
    <ParentComp
      title={"تفاصيل السلف"}
      prevPage={"السلف"}
      linkto={"/advancepayment/"}
    >
      <AdvaceComp />
    </ParentComp>
  );
}

export default Advance;
