import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "../assets/images/Image";
import { Pagination } from "antd";
import { AuthCountext } from "./AuthCountext";
import PlaceHolder from "./subcomponent/PlaceHolder";
import WindowSize from "./subcomponent/WindowSize";

const EmployeesComp = () => {
  const { SERVER_URL, branchId, theme, userToken } = useContext(AuthCountext);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(12);

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(
          `${SERVER_URL}/fetchemployee/getAllEmployees/${currentPage}/${dataInPage}/${branchId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
          loader();
        } else {
          setData([]);
          loader();
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [SERVER_URL, branchId, currentPage, dataInPage, userToken]);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    loader();
  }, []);

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<PlaceHolder />);
    }
    return places;
  };
  const windowSize = WindowSize();
  return (
    <div
      className={`
          flex flex-column align-start justify-start gap-20 full-width `}
      style={{ flexGrow: 1 }}
    >
      <div
        className="flex flex-row flex-wrap gap-20 media-full-width"
        style={{
          minHeight: "600px",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "baseline",
        }}
      >
        {isloading ? (
          <Place />
        ) : data.length > 0 ? (
          data?.map((item) => (
            <Link
              className={`color-${theme} text-decoration media-full-width`}
              to={`/employee/${item.id}`}
              style={{ flexGrow: 1, height: "150px" }}
              key={item.id}
            >
              <div
                style={{
                  padding: "10px",
                  minWidth: "350px",
                  maxWidth: '500px',
                  height: "150px",
                  position: "relative",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
                className={`shadow-${theme} flex flex-column align-start justify-center background-div-${theme} hover-background-div-${theme} before-background-color-${theme} before-text-color-${theme} media-full-width`}
                key={item.id}
              >
                {(item.inwork === "0" || item.inwork === 0) && (
                  <div
                    style={{
                      position: "absolute",
                      left: "-77px",
                      top: "15px",
                      transform: "rotate(320deg)",
                      background: "tomato",
                      zIndex: "revert",
                      opacity: "0.8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "250px",
                    }}
                  >
                    <h1 style={{ fontSize: "20px", color: "white" }}>
                      انتهى عقده
                    </h1>
                  </div>
                )}
                <div
                  className="p-5 flex flex-row align-center justify-end width-10"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "10px",
                  }}
                >
                  {item.id}
                </div>
                <div className="p-5 flex flex-row align-center justify-start gap-10">
                  <div
                    className={`flex flex-row justify-start align-center round over-flow flip`}
                  >
                    {windowSize[0] < 900 ? (
                      <Image
                        image={`${SERVER_URL}/${item.photo}`}
                        w={80}
                        h={80}
                        name={item?.name}
                      />
                    ) : (
                      <Image
                        image={`${SERVER_URL}/${item.photo}`}
                        w={70}
                        h={70}
                        name={item?.name}
                      />
                    )}
                  </div>
                  <div className="flex flex-column align-start justify-end">
                    <span
                      style={
                        windowSize[0] <= 900
                          ? {
                              textAlign: "start",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }
                          : {
                              textAlign: "start",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }
                      }
                    >
                      {item.name.slice(0, 25)}
                      {item.name.length > 25 && "..."}
                    </span>
                    <div
                      style={
                        windowSize[0] <= 900
                          ? {
                              textAlign: "start",
                              fontSize: "12px",
                              fontWeight: "500",
                              opacity: "0.5",
                            }
                          : {
                              textAlign: "start",
                              fontSize: "16px",
                              fontWeight: "500",
                              opacity: "0.5",
                            }
                      }
                    >
                      <span
                        style={{ fontSize: "12px" }}
                        className="flex flex-row align-center justify-start width-10"
                      >
                        {item.phone}
                      </span>
                      <span
                        style={{ fontSize: "12px" }}
                        className="flex flex-row align-center justify-start width-10"
                      >
                        {item.email}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`p-5 flex flex-row align-center hover-affict-${theme} text-center justify-center width-10 border-radius hover-font-color`}
                  style={{
                    position: "absolute",
                    left: "10px",
                    bottom: "10px",
                  }}
                >
                  <Link
                    to={`/employee/updateemployee/${item.id}`}
                    style={{
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                    }}
                    className={`text-center flex justify-center color-${theme} align-center text-decoration edit-card`}
                  >
                    تعديل
                  </Link>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <h1>لا توجد بيانات</h1>
        )}
      </div>

      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default EmployeesComp;
