import "./App.css";

import Land from "./component/Land";
import { ConfigProvider, theme as antdTheme } from "antd";
import arEG from "antd/locale/ar_EG";
import { AuthCountext } from "./component/AuthCountext";
import { useContext } from "react";


function App() {
  
  const { theme } = useContext(AuthCountext);
  let mainthem = "";

  const themes = {
    light: {
      primary: '#1677ff',
      bg: "#fff",
      color: "rgb(33, 43, 54)",
      borderColor: 'rgba(225, 225, 225, 0.5)',
      placeHolder: '#aaa8a8',
      elevate: '#fff',
      hoverBorder: 'rgba(31, 143, 255, 0.5)',
      shadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
      selectedColor: '#fff'
    },
    dark: {
      primary: '#04AA6D',
      bg: "#282828",
      color: "#e1e1e1",
      borderColor: 'rgb(118, 118, 118)',
      placeHolder: '#aaa8a8',
      elevate: '#383838',
      hoverBorder: 'rgba(31, 143, 255, 0.5)',
      shadow: '0 6px 16px 0 rgba(20, 2, 2, 0.08), 0 3px 6px -4px rgba(20, 2, 2, 0.12), 0 9px 28px 8px rgba(20, 2, 2, 0.05)',
      selectedColor: '#e1e1e1'
    },
  };

  theme === "dark" ? (mainthem = themes.dark) : (mainthem = themes.light);

  return (
    <ConfigProvider
      direction="rtl"
      locale={arEG}
      theme={{
        algorithm:  theme === 'dark' && antdTheme.darkAlgorithm,
        token: {
          fontFamily: "Noto Kufi Arabic",
          colorPrimary: mainthem.primary,
          colorText: mainthem.color,
          colorSplit: mainthem.placeHolder,
          colorTextDescription: mainthem.placeHolder,
        },
        components: {
          Button: {
            colorPrimary: mainthem.primary,
            algorithm: true, // Enable algorithm
          },
          DatePicker: {
            // colorPrimary: mainthem.primary,
            colorBgContainer: mainthem.bg,
            // colorText: mainthem.color,
            hoverBorderColor: mainthem.borderColor,
            // colorBorder: mainthem.borderColor,
            // colorTextPlaceholder: mainthem.placeHolder,
            // colorBgElevated: mainthem.elevate,
            // colorIcon: mainthem.primary,
            // colorIconHover: mainthem.hoverBorder,
            activeShadow: mainthem.hoverBorder,
            boxShadow: mainthem.shadow,
            colorPrimaryBg: mainthem.hoverBorder,
            algorithm: true,
            
          },
          Pagination: {
            // colorPrimary: mainthem.primary,
            // colorBgContainer: mainthem.bg,
            // colorText: mainthem.color,
            hoverBorderColor: mainthem.borderColor,
            // colorBorder: mainthem.borderColor,
            // colorTextPlaceholder: mainthem.placeHolder,
            // colorBgElevated: mainthem.elevate,
            // colorIcon: mainthem.primary,
            activeShadow: mainthem.hoverBorder,
            boxShadow: mainthem.shadow,
            hoverBg: mainthem.hoverBorder,
            // colorTextQuaternary: mainthem.primary,
            algorithm: true, // Enable algorithm
          },
          Select: {
            colorBgContainer: mainthem.bg,
            // colorPrimary: mainthem.primary,
            // colorBgContainer: mainthem.bg,
            // colorText: mainthem.color,
            hoverBorderColor: mainthem.borderColor,
            // colorBorder: mainthem.borderColor,
            // colorTextPlaceholder: mainthem.placeHolder,
            // colorBgElevated: mainthem.elevate,
            // colorIcon: mainthem.primary,
            // activeShadow: mainthem.hoverBorder,
            // boxShadow: mainthem.shadow,
            // optionActiveBg: mainthem.primary,
            // optionSelectedBg: mainthem.primary,
            // optionSelectedColor: mainthem.selectedColor,
            algorithm: true, // Enable algorithm
          },
          Progress: {
            // colorText: theme === 'dark' ? '#e3e3e3' : '#282828',
            defaultColor: mainthem.primary,
            // remainingColor: mainthem.placeHolder,
          }
        }
      }}
    >
      <div className="App">
        <Land />
      </div>
    </ConfigProvider>
  );
}

export default App;
