import React, { useContext, useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import CompanyInfos from "../pdfComponent/CompanyInfos";
import { AuthCountext } from "../AuthCountext";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import AdvancePdf from "../pdfComponent/AdvancePdf";
import ParentComp from "../ParentComp";
import InstallmentsPdf from "../pdfComponent/InstallmentsPdf";
import CutPdf from "../pdfComponent/CutPdf";
import AbsencePdf from "../pdfComponent/AbsencePdf";
import VacationPdf from "../pdfComponent/VacationPdf";
import IncreasePdf from "../pdfComponent/IncreasePdf";
import OvertimePdf from "../pdfComponent/OvertimePdf";
import IncentivesPdf from "../pdfComponent/IncentivesPdf";
import RewardsPdf from "../pdfComponent/RewardsPdf";

function PdfBrowserRender() {
  const { userToken, SERVER_URL, branchId } = useContext(AuthCountext);
  const params = useParams();
  const companyInfos = CompanyInfos();
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const getImage = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/imgbase64/${branchId}/img`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setData(json);
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <ParentComp
      title={"طباعة"}
      prevPage={"الصفحة السابقة"}
      linkto={`/${params.page}`}
    >
      {isloading ? (
        <Loader size="large" />
      ) : (
        <PDFViewer language="ar" style={{ width: "80%", height: "100%" }}>
          {params.page === "advance" && (
            <AdvancePdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "installments" && (
            <InstallmentsPdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "cut" && (
            <CutPdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "absence" && (
            <AbsencePdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "vacation" && (
            <VacationPdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "increase" && (
            <IncreasePdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "overtime" && (
            <OvertimePdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "incentives" && (
            <IncentivesPdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
          {params.page === "rewards" && (
            <RewardsPdf
              img64={data}
              companyInfos={companyInfos}
              employeeId={params.id}
              userToken={userToken}
              url={SERVER_URL}
              branchId={branchId}
            />
          )}
        </PDFViewer>
      )}
    </ParentComp>
  );
}

export default PdfBrowserRender;
