import ParentComp from "../ParentComp";
import PayMethodComp from "./PayMethodComp";

function PyaMethod() {
  return (
    <>
      <ParentComp
        title={"طرق الصرف"}
        prevPage={"الاعدادات"}
        linkto={"/settings/"}
      >
        <PayMethodComp />
      </ParentComp>
    </>
  );
}

export default PyaMethod;
