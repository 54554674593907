import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";

function UlPlaceHolder({ data }) {
  const { theme } = useContext(AuthCountext);
  return (
    <ul className={`border-bottom-${theme}-1`} style={{ minWidth: "1275px", height: '60px' }}>
      <li className="p-5 flex flex-row align-center justify-start width-5-100">
        <div
          style={{
            width: "40px",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-40-100 gap-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "90%",
            borderRadius: "5px",
            height: "31PX",
            
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
    </ul>
  );
}

export default UlPlaceHolder;
