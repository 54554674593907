import ParentComp from "./ParentComp";
import SalaryComp from "./SalaryComp";

function Salary() {
  return (
    <>
      <ParentComp title={"الرواتب"} prevPage={"الرئيسية"} linkto={"/"}>
        <SalaryComp />
      </ParentComp>
    </>
  );
}

export default Salary;
