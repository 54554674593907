import React from "react";

function Loader({ size, color }) {
  let w = 0;
  let borderSize = 0
  size === "small" ? (w = 10) : size === "medium" ? (w = 40) : (w = 100);
  size === 'small' || size === 'medium' ? borderSize = 4 : borderSize = 8
  let borderColor;
  color === "white"
    ? (borderColor = "#f5f4f4")
    : color === "gray"
    ? (borderColor = "gray")
    : (borderColor = "#3498db");
  return (
    <div
      className="loader justify-center align-center"
      style={{
        width: w,
        borderColor: borderColor,
        borderTopColor: "transparent",
        borderWidth: borderSize,
        filter: 'invert(0)'
      }}
    ></div>
  );
}

export default Loader;
