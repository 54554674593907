import ParentComp from "../ParentComp";
import InstallmentsComp from "./InstallmentsComp";

function Installments() {
  return (
    <>
      <ParentComp
        title={"الاقساط"}
        prevPage={"السلف"}
        linkto={"/advancepayment/"}
      >
        <InstallmentsComp />
      </ParentComp>
    </>
  );
}

export default Installments;
