import ParentComp from "../component/ParentComp";
import UsersAuthBranchComp from "./UsersAuthBranchComp";

function UsersAuthBranch() {
  return (
    <>
      <ParentComp
        title={"اضافة صلاحيات الفرع"}
        prevPage={"اعدادات المستخدمين"}
        linkto={"/settings/users"}
      >
        <UsersAuthBranchComp />
      </ParentComp>
    </>
  );
}

export default UsersAuthBranch;
